export const SELECTED_KEY = '_SELECTED';
export const TOGGLED_KEY = '_TOGGLED';
export const LEVEL_KEY = '_LEVEL';

export const generateVisibleItems = (items, oldVisibleItems = new Map()) => {
  const oldSelected = oldVisibleItems.filter((i) => i.get(SELECTED_KEY, false)).keySeq();
  const oldToggled = oldVisibleItems.filter((i) => i.get(TOGGLED_KEY, false)).keySeq();

  const isToggled = (key) => oldToggled.includes(key);
  const isSelected = (key) => oldSelected.includes(key);

  const getModifiedItem = (item, key, level = 0) => {
    const modificatior = new Map({
      ...(isSelected(key) ? { [SELECTED_KEY]: true } : {}),
      ...(isToggled(key) ? { [TOGGLED_KEY]: true } : {}),
    });
    return item.set(LEVEL_KEY, level).merge(modificatior);
  };

  const getChildItems = (item, key, level) => (isToggled(key)
    // eslint-disable-next-line no-use-before-define
    ? getItems(item.get('Items', new Map()), level + 1)
    : new Map());

  const getItems = (i, level = 0) => i.reduce(
    (R, item, key) => R
      .set(key, getModifiedItem(item, key, level))
      .merge(getChildItems(item, key, level)),
    new Map(),
  );

  return getItems(items);
};

export const indexTable = (t, offset = 0, parent = null) => {
  const tt = t.filter((r) => r.get('Parent') === parent);
  return tt.reduce((tbl, item, k) => {
    const childItems = indexTable(t, tbl.size + offset + 1, k);
    return tbl
      .set(k, item.set('Index', tbl.size + offset + 1))
      .merge(childItems);
  }, new Map());
};
