// Тут только ОБЛАСТИ!!!!!

export const regionDbOptions = [
  // {
  //   text: 'Рівненська область',
  //   value: '17obl.ciat.net.ua/BM',
  //   modes: [buildModes.prod, buildModes.dev],
  //   AP: 'office',
  // },
];
export default regionDbOptions;
