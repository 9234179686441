import styled from 'styled-components';
import { font } from '../../../../constants/globalStyle';

const ButtonStyled = styled.button.attrs({
  type: 'button',
})`
  padding: 2px 6px;
  border-radius: .28571429em;
  border: 1px solid #afbbcc;
  background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  color: #4D4D4D;
  cursor: pointer;
  outline: none;
  &:hover{
    background: #AAC6E6;
  }
`;

export const SelectButton = styled(ButtonStyled)`
  ::after {
    content: "...";
  }
`;

export const EraseButton = styled(ButtonStyled)`
  ::after {
    content: "X";
  }
`;

export const CalendarDiv = styled.div`
  display: grid;
  grid-auto-columns: 31px;
  padding: 10px 5px;

`;

const DefaultCellDiv = styled.div`
  font-size: small;
  font-weight: 500;
  text-align: center;
  padding: 1px;
`;

export const CellDiv = styled(DefaultCellDiv)`
  grid-area: ${({ area }) => area};
  color: ${({ color }) => color};
  background: ${({ active }) => (active ? '#add9fd' : 'unset')};
  border-radius: 50%; 
  padding: 4px 0 5px 0;
  cursor: pointer;
  text-decoration: ${({ disabled }) => (disabled ? 'line-through' : 'unset')};
  ${({ disabled }) => (disabled ? '' : `
  :hover {
    background: #badec0;
  }
  `)}
`;

export const HeaderDiv = styled(DefaultCellDiv)`
  grid-area: ${({ area }) => area};
  color: ${({ color }) => color};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
   span:first-letter{
     text-transform: uppercase;
    }
`;
export const MonthText = styled.span`
  font-weight: 600;
  color: #548ccd;
`;

export const PortalDiv = styled.div`
  // margin-top: 25px;
  position: absolute;
  top: ${({ top }) => top}px;
  // right: 0;
  border-radius: .28571429rem;
  // border: 1px solid rgba(34,36,38,.15);
  border: 1px solid #afbbcc;
  background: white;
  z-index: 1001;
`;

export const DatePcikerButton = styled.div`
  cursor: pointer;
  color: blue;
  display: grid;
`;

export const CalendarHr = styled.hr`
  border: 0.5px solid #008f21;
  width: 100%;  
`;

export const DivStyled = styled.div`
  display: flex;
  border: ${(props) => (props.noBorder ? 'none' : '1px solid #afbbcc')};
  border-radius: .3em;
  // margin: 4px 0 7px 0 ;
`;

export const InputStyled = styled.input`
  width: 100%;
  min-width: 1px;
  border: none;
  padding: 3px 2px;
  outline: none;
  background-color: ${({ background }) => (background ? 'transparent' : 'white')};
  position: relative;
  border-radius: .3rem;
  ${font};
`;

export const TwoArrowImg = styled.img`
  width: 22px;
`;

export const OneArrowImg = styled.img`
  width: 14px;
`;
