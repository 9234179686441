/* eslint-disable react/no-array-index-key */
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import TPHeader from './header';
import Placeholder from '../../../../../newEditor/tablePart/placeholder';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableHeader from '../../../../../newEditor/tablePart/tableHeader';
import md from '../../../../../../constants/meta/documents/indicatorsStartYear';
import useTablePart from '../../../../../newEditor/hook/tablepart';
// eslint-disable-next-line import/no-cycle
import Treeview from '../../../treeView';

const tableMeta = md.tables.general;

const inTotalColumns = [
  'к1_4',
  'к5_9',
  'к10_11',
  'Ясельные',
  'Дошкольные',
];
export const totalColumns = [
  ...inTotalColumns,
  'КоличествоГрупп',
  'КоличествоУчеников',
  'КоличествоЧасов',
];

const GeneralTP = ({ data, actions }) => {
  const tableData = data['ДеревоПоказателей'] || [];
  const headerData = data['ВидимыеКолонки'] || [];

  const {
    tableActions, activeCol, tableContainerRef, highlights, pinHeader,
  } = useTablePart({
    tableData,
    tableName: tableMeta.name,
    onChange: actions.onChange,
  });

  const onChange = useCallback(
    (newData) => {
      const flattenTree = (nodes) => {
        const result = [];
        const traverse = (node) => {
          const hasChild = Array.isArray(node.child) && node.child.length > 0;
          const { child, ...rest } = node;
          result.push({ ...rest, hasChild });
          if (hasChild) {
            node.child.forEach(traverse);
          }
        };
        if (Array.isArray(nodes)) {
          nodes.forEach(traverse);
        } else {
          traverse(nodes);
        }
        return result;
      };
      const flatData = flattenTree(newData).filter((row) => !row.hasChild);
      const newTableData = flatData.reduce((R, row) => [
        ...R,
        ...totalColumns.filter((col) => !!row[col]).map((col) => ({
          [tableMeta.columns.indicator.name]: row['Показатель'],
          [tableMeta.columns.clas.name]: col,
          [tableMeta.columns.sum.name]: row[col],
        })),
      ], []);
      actions.onChange({ ДеревоПоказателей: newData, Показатели: newTableData });
    },
    [actions],
  );

  return (
    <Card className="border-0">
      <TableHeader pinHeader={pinHeader}>
        <TPHeader
          activeCol={activeCol}
          highlights={highlights}
          onToggleHighlght={tableActions.onToggleHighlightColumn}
          visibleColumns={headerData}
        />
      </TableHeader>
      <Card.Body className="p-0" ref={tableContainerRef}>
        <Treeview
          tableData={tableData}
          selection
          separated
          visibleColumns={headerData}
          onChange={onChange}
        />
        <Placeholder onFocus={(e) => tableActions.onAddRow(e)} />
      </Card.Body>
    </Card>
  );
};

GeneralTP.propTypes = {
  data: PropTypes.shape({
    ДеревоПоказателей: PropTypes.arrayOf(PropTypes.shape({})),
    ВидимыеКолонки: PropTypes.arrayOf(PropTypes.shape({})),
    [md.columns.CSU.name]: referencePropType,
    [tableMeta.name]: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      children: PropTypes.shape([]),
      disabled: PropTypes.bool,
    })),
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
};

export default memo(GeneralTP);
