import React from 'react';
import PropTypes from 'prop-types';
import { CommandPanelButtonText } from '../Form/styledForm';
import Modal from '../modal';
import { SettingsIcon } from '../../assets/icons';

const ReportSettingsPortal = ({ disabled, children }) => (
  <Modal
    triggerButton={(
      <CommandPanelButtonText disabled={disabled} text="Налаштування">
        <SettingsIcon />
      </CommandPanelButtonText>
    )}
    header="Налаштування"
  >
    {React.Children.only(children)}
  </Modal>
);
ReportSettingsPortal.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

ReportSettingsPortal.defaultProps = {
  disabled: false,
};

export default ReportSettingsPortal;
