import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../constants/meta/documents/changeToStaffList';

import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { AppContext } from '../../../../providers/authProvider';
import { CommandPanelColor } from '../../../../components/Form/styledForm';

const ChangeToStaffListEditor = ({ data, actions, permissions }) => {
  const appContext = useContext(AppContext);
  const { sessionOptions } = appContext;
  const FI = data[md.columns.csu.name];
  const FiOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  const readOnly = !permissions.canChange;
  return (
    <EditorCollapse>
      {sessionOptions.is_admin && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.number.label}
            value={data[md.columns.number.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.number.name]: value,
            })}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.baseDoc.label}
            value={data[md.columns.baseDoc.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.baseDoc.name]: value,
            })}
            modelType="cat"
            modelName="baseDoc"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.kbp.label}
            value={data[md.columns.kbp.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.units.name]: value,
            })}
            modelType="cat"
            modelName="kbpClassifier"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.fond.label}
            value={data[md.columns.fond.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.fond.name]: value,
            })}
            modelType="cat"
            modelName="elementFond"
            readOnly={readOnly}
            filter={FiOwnerFilter}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.koef.label}
            value={data[md.columns.koef.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.koef.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`isApproved-${data.id}`}
            label={md.columns.isApproved.label}
            value={data[md.columns.isApproved.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.isApproved.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CommandPanelColor
            label="Розрахувати все"
            disabled={readOnly}
            onClick={() => actions.onSR('RECOUNT_ALL')}
          />
        </Col>
      </Row>
    </EditorCollapse>
  );
};

ChangeToStaffListEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default ChangeToStaffListEditor;
