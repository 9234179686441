import { buildModes } from './common';

export const testDbOptions = [
  {
    text: 'тестова Tarif',
    value: '1c-dev-02.ciat.net.ua/Tarif_H',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'тестова Tarif Тарас',
    value: '1c-dev-02.ciat.net.ua/Gusar_Tar',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'тестова Tarif для користувачів',
    value: '1c-dev-02.ciat.net.ua/Tarif_test',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
  {
    text: 'тестова 2',
    value: '1c-dev-02.ciat.net.ua/Max',
    modes: [buildModes.dev, buildModes.prod],
    AP: 'office',
  },
];
export default testDbOptions;
