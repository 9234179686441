import React, { forwardRef, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Navbar, ButtonToolbar, ButtonGroup, Modal,
} from 'react-bootstrap';
import {
  ApproveButton, UnApproveButton, StatusButton, HistoryButton,
  CopyButton, DeleteButton, EditButton, ExecuteButton, NewButton,
  SetPeriodButton, ShowDeletedToggler, UnexecuteButton, RefreshButton,
  GoToOldVersionButton,
} from './buttons';
import SearchInput from './elements';
import { StyledButtonToolbar } from './styles';

import ListerContext from '../../context';

const DocListerCommandPanel = forwardRef(({
  children,
}, ref) => {
  const {
    actions, permissions, showDeleted, searchString, period, selectedRows, approveStatus,
  } = useContext(ListerContext);

  return (
    <Navbar sticky="top" className="px-0 bg-white" ref={ref}>
      <StyledButtonToolbar className="me-auto">
        <ButtonGroup>
          <NewButton
            onClick={actions.onNew}
            disabled={!(permissions.canNew && actions.onNew)}
          />
          <CopyButton
            onClick={actions.onCopy}
            disabled={!(permissions.canCopy && actions.onCopy)}
          />
          <EditButton
            onClick={actions.onEdit}
            disabled={!(permissions.canEdit && actions.onEdit)}
          />
          <DeleteButton
            onClick={actions.onDelete}
            disabled={!(permissions.canDelete && actions.onDelete)}
          />
          <RefreshButton
            onClick={actions.onRefresh}
          />
        </ButtonGroup>
        <ButtonGroup>
          <ExecuteButton
            onClick={actions.onExecute}
            disabled={!(permissions.canExecute && actions.onExecute)}
          />
          <UnexecuteButton
            onClick={actions.onUnexecute}
            disabled={!(permissions.canUnexecute && actions.onUnexecute)}
          />
        </ButtonGroup>
        <ButtonGroup>
          <SetPeriodButton value={period} onChange={actions.onSetPeriod} />
        </ButtonGroup>

        {permissions.canSign && (
          <ButtonGroup>
            <ApproveButton onClick={actions.onApproveItems} disabled={!selectedRows.length} />
            <UnApproveButton onClick={actions.onUnApproveItems} disabled={!selectedRows.length} />
            <StatusButton
              onClick={actions.onApproveStatus}
              disabled={!selectedRows.length}
              status={approveStatus}
              onClose={actions.onCloseApproveStatus}
            />
            <HistoryButton
              onClick={actions.onApproveHistory}
              disabled={!selectedRows.length}
              status={approveStatus}
              onClose={actions.onCloseApproveStatus}
            />
            <Modal
              size="small"
              // header={`Журнал док. ${actions.message.repr}`}
              closeIcon
              opened={approveStatus.visible && false}
              onClose={actions.onCloseApproveStatus}
            >
              {/* { */}
              {/*  actions.message.message && actions.message.message.map((el) => ( */}
              {/*    <h3> */}
              {/*      {' '} */}
              {/*      {el} */}
              {/*      {' '} */}
              {/*    </h3> */}
              {/*  )) */}
              {/* } */}

            </Modal>
          </ButtonGroup>
        )}

        {children}
      </StyledButtonToolbar>
      <ButtonToolbar>
        <ShowDeletedToggler
          value={showDeleted}
          disabled={!(permissions.canShowDeleted && actions.onToggleShowDeleted)}
          onChange={actions.onToggleShowDeleted}
        />
        <SearchInput
          onChange={actions.onSearch}
          value={searchString}
          disabled={!permissions.canSearch && !searchString}
        />
      </ButtonToolbar>
      <ButtonGroup className="float-right">
        <GoToOldVersionButton
          onClick={actions.onGoToOldVersion}
        />
      </ButtonGroup>
    </Navbar>
  );
});

DocListerCommandPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

DocListerCommandPanel.defaultProps = {
  children: null,
};

export default memo(DocListerCommandPanel);
