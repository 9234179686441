import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Navbar, ButtonGroup,
} from 'react-bootstrap';
import {
  SaveButton,
  SaveNExitButton,
  UndoButton,
  RedoButton,
  GoToOldVersionButton,
} from './buttons';
import { StyledButtonToolbar } from './styles';

const CatEditorCommandPanel = forwardRef(({
  children, actions, permissions, changed,
}, ref) => (
  <Navbar sticky="top" ref={ref} className="border rounded bg-white">
    <StyledButtonToolbar className="me-auto" size="sm">
      <ButtonGroup size="sm">
        <SaveNExitButton
          onClick={actions.onSaveNExit}
          disabled={!permissions.canClose || !permissions.canSave || !changed}
        />
        <SaveButton
          onClick={actions.onSaveWithoutExit}
          disabled={!changed || !permissions.canSave}
        />
      </ButtonGroup>
      <ButtonGroup size="sm">
        <UndoButton
          onClick={actions.onUndo}
          disabled={!permissions.canUndo}
        />
        <RedoButton
          onClick={actions.onRedo}
          disabled={!permissions.canRedo}
        />
      </ButtonGroup>
      {children}
    </StyledButtonToolbar>
    <ButtonGroup size="sm">
      <GoToOldVersionButton
        onGoToOldVersion={actions.onGoToOldVersion}
        onSaveNGoToOldVersion={actions.onSaveNGoToOldVersion}
        isChanged={changed}
      />
    </ButtonGroup>
  </Navbar>
));

CatEditorCommandPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  actions: PropTypes.shape({
    onSaveNExit: PropTypes.func.isRequired,
    onSaveWithoutExit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onUndo: PropTypes.func.isRequired,
    onRedo: PropTypes.func.isRequired,
    onGoToOldVersion: PropTypes.func.isRequired,
    onSaveNGoToOldVersion: PropTypes.func.isRequired,
  }).isRequired,
  changed: PropTypes.bool,
  permissions: PropTypes.shape({
    canClose: PropTypes.bool,
    canSave: PropTypes.bool,
    canUndo: PropTypes.bool,
    canRedo: PropTypes.bool,
  }).isRequired,
};

CatEditorCommandPanel.defaultProps = {
  children: null,
  changed: false,
};

export default memo(CatEditorCommandPanel);
