import { allDbOptions } from './bases';

export const proxyApi = {
  _dev: 'http://192.168.1.100:8000/',
  local: 'http://localhost:8000/',
  office: 'https://authapi01.ciat.net.ua/',
  deltahost: 'https://authapi02.ciat.net.ua/',
};
// export const proxyApi = 'http://localhost:8000/';

/**
 * @type {RegExp}
 */
export const dateRegex = /^\d{4}-\d{2}-\d{2}T[0-2]\d:\d{2}:\d{2}$/g;

//* *************************************//
// allDbOptions смотри в каталоге bases //
//* *************************************//

export const dbOptions = allDbOptions.filter((o) => o.modes.includes(process.env.NODE_ENV));

export const RELOAD_TIMEOUT = 10 * 60 * 1000; // 10 min

export default {
  dbOptions,
  proxyApi,
  dateRegex,
  RELOAD_TIMEOUT,
};
