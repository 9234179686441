import {
  defaultOrder,
  defaultColumns,
  reqListerColumns,
} from '../common';

const columns = {
  ...defaultColumns,
  LevelAdmin: {
    label: 'Адміністратор рівня',
    name: 'АдминистраторУровня',
    visible: false,
  },
  WithoutIncomeInAnalise: {
    label: 'Не показувати доходи в підсистемі "Аналітика"',
    name: 'БезДоходовВАналитике',
    visible: false,
  },
  WithoutTranscript: {
    label: 'Не показувати розшифровку  документів у звітах',
    name: 'БезРасшифровки',
    visible: false,
  },
  WithoutAccounts: {
    label: 'Не показувати рахунки при виборі розпорядника',
    name: 'БезСчетов',
    visible: false,
  },
  Budget: { label: 'Бюджет, доступний користувачу', name: 'Бюджет', visible: false },
  AccessToInfoBaseGranted: {
    label: 'Доступ до ІБ дозволений',
    name: 'ДоступКИнфорационнойБазеРазрешен',
    visible: true,
    type: 'bool',
  },
  Email: {
    label: 'Електронна адреса',
    name: 'ЕлектронныйАдрес',
    visible: false,
  },
  RestrictToRemoveSignFromPlanDocs: {
    label: 'Заборонити знімати підпис с документів планування',
    name: 'ЗапретитьСниматьПодписьСДокументовПланирования',
    visible: false,
  },
  RestrictPassChange: {
    label: 'Заборонити змінювати пароль',
    name: 'ЗапрещеноИзменятьПароль',
    visible: false,
  },
  ID77: { label: 'Ід77', name: 'Ид77', visible: false },
  UserIDIB: {
    label: 'Ідентифікатор користувача ІБ',
    name: 'ИдентификаторПользователяИБ',
    visible: false,
  },
  Name: { label: 'Имя', name: 'Имя', visible: false },
  ContractCode: {
    label: 'Код договору',
    name: 'КодДоговора',
    visible: true,
  },
  TelephoneNumber: {
    label: 'Номер телефону',
    name: 'НомерТелефона',
    visible: false,
  },
  Password: { label: 'Пароль', name: 'Пароль', visible: false },
  PasswordHasBeenInstalled: {
    label: 'Пароль встановлений',
    name: 'ПарольУстановлен',
    visible: false,
  },
  PasswordF: { label: 'Пароль Ф', name: 'ПарольФ', visible: false },
  PasswordConfirmation: {
    label: 'Підтвердження пароля',
    name: 'ПодтверждениеПароля',
    visible: false,
  },
  ShowInSelectList: {
    label: 'Показувати в списку вибору',
    name: 'ПоказыватьВСпискеВыбора',
    visible: false,
  },
  FullName: { label: 'Повне ім\'я', name: 'ПолноеИмя', visible: true },
  Note: { label: 'Примітка', name: 'Примичание', visible: true },
  WorkAfter: { label: 'Робота після', name: 'РаботаПосле', visible: false },
  AllowedToChangeDateOfRestriction: {
    label: 'Дозволити міняти дату заборони на зміну документів',
    name: 'РазрешеноМенятьДатуЗапрета',
    visible: false,
  },
  AllowAccessToStewardsOfLowerLevels: {
    label: 'Дозволити доступ до розпорядників нижчого рівня',
    name: 'РазрешитьДоступКРспорядтелямНижнегоУровня',
    visible: false,
  },
  AllowImportOfTreasuryFiles: {
    label: 'Дозволити імпорт казначейських файлів',
    name: 'РазрешитьИмпортКазначейскихФайлов',
    visible: false,
  },
  AllowMultipleSessions: {
    label: 'Дозволити користувачу запускати кілька сеансів',
    name: 'РазрешитьМножественныеСеансы',
    visible: false,
  },
  AllowRerunOfEnforcedRequests: {
    label: 'Дозволити перепроведення затверджених заявок',
    name: 'РазрешитьПерепроведениеУтвердженныхЗаявок',
    visible: false,
  },
  AllowViewingOwnRequestsOnHighestLevel: {
    label: 'Дозволити перегляд власних заявок на рівні розпорядника вищого рівня',
    name: 'РазрешитьПросмотрСвоихЗаявокНаВерхнемУровне',
    visible: false,
  },
  AllowToEditTreasuryAccounts: {
    label: 'Дозволити редагувати казначейські рахунки',
    name: 'РазрешитьРедактироватьКазначейскиеСчета',
    visible: true,
    type: 'bool',
  },
  AllowEditBoilerTreasuryAccounts: {
    label: 'Дозволити редагувати КОТЛОВІ казначейські рахунки',
    name: 'РазрешитьРедактироватьКотловыеКазначейскиеСчета',
    visible: false,
  },
  AllowEditToStewardsOfHL: {
    label: 'Дозволити редагування казначейських рахунків розпорядника вищого рівня',
    name: 'РазрешитьРедактированиеКазначейскихСчетовРаспорядителяВУ',
    visible: false,
  },
  AllowEditBudgetCatalogs: {
    label: 'Дозволити редагувати довідники що відносяться до бюджету',
    name: 'РазрешитьРедактироватьСправочникиПоБюджету',
    visible: false,
  },
  AllowEditCatalogsOR: {
    label: 'Дозволити редагувати довідники об\'єктів ремонту',
    name: 'РазрешитьОбъектыРемонта',
    visible: false,
  },
  AllowEditCatalogsOB: {
    label: 'Дозволити редагувати довідники об\'єктів будівництва',
    name: 'РазрешитьОбъектыСтроительства',
    visible: false,
  },
  AllowEditCatalogsForPCM: {
    label: 'Дозволити редагувати довідники що відносяться до ПЦМ',
    name: 'РазрешитьРедактироватьСправочникиПоПЦМ',
    visible: false,
  },
  AllowEditChargingCatalogs: {
    label: 'Дозволити редагувати довідники що відносяться до "Тарифікації"',
    name: 'РазрешитьРедактироватьСправочникиТарификации',
    visible: false,
  },
  AllowEditClassifierElements: {
    label: 'Дозволити редагувати довідники з елементами класифікаторів',
    name: 'РазрешитьРедактироватьЭлементыКлассификаторов',
    visible: false,
  },
  AllowSignGroupManage: {
    label: 'Дозволити управління групами підписів',
    name: 'РазрешитьУправленияГруппамиПодписей',
    visible: false,
  },
  EditOnlyOwnDocuments: {
    label: 'Редагувати тільки свої документи',
    name: 'РедактироватьТолькоСвоиДокументы',
    visible: false,
  },
  Roles: { label: 'Роль', name: 'Роли', visible: false },
  FinancialInstitution: {
    label: 'Організація, в якій працює користувач',
    name: 'ФинансовыйОрган',
    visible: true,
  },
  ForbidToSpendAdditionalFinancing: {
    label: 'Заборонити проведення документу "Розпорядження/Розподіл"',
    name: 'флЗапретитьПроводитьДокФинансирование',
    visible: false,
  },
  TimeLimit: {
    label: 'Обмеження по часу',
    name: 'флОграничениеПоВремени',
    visible: false,
  },
  Head: { label: 'Керівник', name: 'флРуководитель', visible: false },
  HeadOB: {
    label: 'Керівник (об\'єкти будівництва)',
    name: 'ЭтоРуководительОбъекты',
    visible: false,
  },
  Import: {
    label: 'Імпорт ОС в Бюджетний запит (за замовчуванням вимкнено!)',
    name: 'ИмпортОС_В_БЗ',
    visible: false,
  },
  ChangeConnectionLineToDB: {
    label: 'Зміна рядку підключення бази',
    name: 'флСменаСтрокиПодключенияБазы',
    visible: false,
  },
  Language: { label: 'Мова', name: 'Язык', visible: false },
  PanelSectionLocation: {
    label: 'Розташування панелі розділів',
    name: 'РасположениеПанелиРазделов',
    visible: false,
  },
  CreateActualOutcome: {
    label: 'Дозволити створювати документ "Фактичні видатки"',
    name: 'СоздаватьФактическиеРасходы',
    visible: false,
  },
  ChangeFO: {
    label: 'Змінювати "Фінансовий орган" у документі'
      + ' "Інвестиційна пропозиція по об\'єктам будівництва/ремонту"',
    name: 'флИзменятьФОвДокПропозиция',
    visible: false,
  },
  OficiersCreationBan: {
    label: 'Заборонити створення посадових осіб',
    name: 'ЗапретНаСозданиеДолжностныхЛиц',
    visible: false,
  },
  // Дополнительніе свойства
  allowedRoles: {
    label: 'Доступные роли',
    name: 'ДоступныеРоли',
    visible: false,
  },
  allowedLanguages: {
    label: 'Доступные языки',
    name: 'ДоступныеЯзыки',
    visible: false,
  },
  code: {
    label: 'Код', name: 'Код', visible: false, width: 'fit-content(150px)',
  },
};

const tables = {
  budget: {
    label: 'Додатково бюджети',
    name: 'Бюджеты',
    columns: {
      number: { label: '№', name: 'НомерСтроки' },
      budget: { label: 'Бюджетна програма', name: 'Бюджет' },
    },
  },
  higherLevelManager: {
    label: 'Додатково розп. вищ. рівня',
    name: 'ФинансовыеОрганы',
    columns: {
      number: { label: '№', name: 'НомерСтроки' },
      FO: { label: 'Розпорядник вищого рівня', name: 'ФинансовыйОрган' },
    },
  },
};

const footer = { ...columns };

export const users = {
  label: 'Користувачi',
  name: 'users',
  columns,
  footer,
  tables,
  backendName: 'Пользователи',
  frontend: 'cat/users',
  listColumns: [
    ...new Set([
      ...reqListerColumns,
      'AccessToInfoBaseGranted',
      'FinancialInstitution',
      'ContractCode',
      'Note',
      'AllowToEditTreasuryAccounts',
    ]),
  ],
  defaultOrder,
  hierarchy: false,
};

export default users;
