import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';
import {
  InfoIcon,
  LogoCiatIcon,
  PhoneIcon,
  FaxIcon,
  MobilePhoneIcon,
  EmailIcon,
} from '../../assets/icons';
// import { Major, Minor } from '../../version';
// import { branch, commitDate, commitId } from '../../sub_version';

const ButtonInfo = styled.button`
  position: relative;
  outline: none;
  cursor: pointer;
  padding: 6px 8px;
  border: none;
  font: inherit;
  color: inherit;
  background: linear-gradient(180deg, #E5EDF7 0%, #BDD3EC 100%);
  border-radius: 4px;
  line-height: 0;
   &:hover{
     background: #bdd3ec;
  };
`;
const BoxInfo = styled.div`
    position: fixed;
    z-index: 10;
    // right: 5%;
    bottom: 8.4%;
    left: 5%;
    background: white;
    border: 1px solid #DDE2E9;
    border-radius: 0.3rem;
    padding: 10px;
    width: 400px;
    border: 1px solid #37a0a7;
    box-shadow: 0 0 66px 5px rgba(0,0,0,0.19);
    // 0 0 20px 8px rgba(0,0,0,0.19);
    ::before{
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 10%;
        bottom: -30px;
        border: 15px solid;
        border-color: #ffffff transparent transparent #ffffff;
    }
`;
const InfoText = styled.span`
    color: #4D4D4D;
    font-size: 14px;
`;
const ContainerInfo = styled.div`
    display: grid;
    grid-template-columns: 10% 88%;
    grid-row-gap: 10px;
    padding: 10px;
    margin-top: 15px;
`;
const ContainerLogoLink = styled.a`
  display: flex;
  align-items: center;
`;
const LogoImg = styled.img`
  width: 50px;
`;
const TextCompany = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #386EAB;
  max-width: 100%;
  margin-left: 10px;
  :hover {
    text-decoration: underline;
    filter: grayscale(0.4);
  };
`;
const ContainerShine = styled.div`
  position: relative;
  overflow: hidden;
  :hover {
    filter: grayscale(0.4);
  };
`;
const rotate = keyframes`
  0% {
    transform: skewX(0deg) translateX(0px);
  }
  90% {
    transform: skewX(0deg) translateX(0px);
  }
  100% {
    transform: skewX(20deg) translateX(200px);
  }
`;
const Shine = styled.div`
  position: absolute;
  top: 0;
  left: -80px;
  height: 100px;
  width: 30px;
  background: rgba(255, 255, 255, 0.2);
  transform: skewX(20deg) translateX(0);
  animation: ${rotate} 10s linear infinite;
`;
const CloseButton = styled.button`
  border: none;
  background: none;
  color: #5f5f5f;
  align-self: flex-end;
  outline: none;
  padding-bottom: 0;
  line-height: 0.5;
  cursor: pointer;
  &:hover{
    color: black;
  };
`;

const InfoDashboard = ({
  content,
  disabled,
}) => {
  // const [state, setState] = useState({
  //   isVisible: false,
  //   bodyOverflow: document.body.style.overflow,
  // });
  const [isVisible, setIsVisible] = useState(false);
  const [bodyOverflow, setBodyOverflow] = useState(document.body.style.overflow);

  useEffect(() => {
    if (isVisible) {
      setBodyOverflow('hidden');
    } else {
      setBodyOverflow(bodyOverflow);
    }
  }, [isVisible, bodyOverflow]);

  // const { isVisible } = state;

  return (
    <div>
      <ButtonInfo
        onClick={() => setIsVisible(!isVisible)}
        disabled={disabled}
        title={content}
      >
        <InfoIcon />
      </ButtonInfo>
      {isVisible && createPortal(
        (
          <BoxInfo>
            <div style={{ display: 'grid', justifyContent: 'end' }}>
              <CloseButton
                onClick={() => setIsVisible(!isVisible)}
              >
                x
              </CloseButton>
            </div>
            <ContainerLogoLink href="https://ciat.net.ua/uk/" target="blank">
              <ContainerShine>
                <LogoImg src={LogoCiatIcon} alt="ЦІАТ" title="ЦІАТ" />
                <Shine />
              </ContainerShine>
              <TextCompany>
                Центр інформаційних та аналітичних технологій
              </TextCompany>
            </ContainerLogoLink>
            <ContainerInfo>
              <img src={PhoneIcon} width="20px" alt="Телефон" title="Телефон" />
              <InfoText>
                (044) 364-07-87,
                <br />
                (044) 364-07-84;
              </InfoText>
              <img src={FaxIcon} width="20px" alt="Факс" title="Факс" />
              <InfoText>(044) 424-04-34;</InfoText>
              <img src={MobilePhoneIcon} width="20px" alt="Мобільний" title="Мобільний" />
              <InfoText>
                (067) 825-43-71,
                <br />
                (067) 825-43-72,
                <br />
                (066) 382-19-98,
                <br />
                (050) 858-07-59;
              </InfoText>
              <img src={EmailIcon} width="20px" alt="e-mail" title="e-mail" />
              <InfoText>
                buh@ciat.net.ua (бухгалтерія),
                <br />
                support@ciat.net.ua (підтримка)
              </InfoText>
            </ContainerInfo>

          </BoxInfo>
        ), document.body,
      )}
    </div>
  );
};

InfoDashboard.propTypes = {
  content: PropTypes.string,
  disabled: PropTypes.bool,
};

InfoDashboard.defaultProps = {
  content: 'Інформація',
  disabled: false,
};

export default InfoDashboard;
