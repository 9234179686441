import styled from 'styled-components';
import { ButtonToolbar } from 'react-bootstrap';

export const StyledButtonToolbar = styled(ButtonToolbar)`
  & > *+* {
    padding-left: .5rem;
  }
`;

export default StyledButtonToolbar;
