import React, {
  memo, useMemo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/changeToStaffList';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tableRowCell';

const tablename = 'main';

const tableMD = md.tables[tablename];

const TPRow = ({
  row, active, rowIndex, activeCol, readOnly, highlights, FI, onChange, onSR,
}) => {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const fondFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  const onCalcChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.stateUnit.name]: v });
      await onSR('CHANGE_STATE_UNIT_IN_TABLE_MAIN', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol} noGutters>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col
            className="d-flex"
          >
            <Col>
              <TableRowCell column="stateUnit" highlighted={highlights.includes('stateUnit')}>
                <ItemPicker
                  value={row[tableMD.columns.stateUnit.name]}
                  onChange={onCalcChange}
                  modelType="cat"
                  modelName="stateUnits"
                  readOnly={readOnly}
                  filter={fondFilter}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="num" highlighted={highlights.includes('num')}>
                <NumberInput
                  value={row[tableMD.columns.num.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.num.name]: v })}
                  precision={3}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="sol" highlighted={highlights.includes('sol')}>
                <NumberInput
                  value={row[tableMD.columns.sol.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.sol.name]: v })}
                  precision={2}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="add" highlighted={highlights.includes('add')}>
                <NumberInput
                  value={row[tableMD.columns.add.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.add.name]: v })}
                  precision={2}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="pay" highlighted={highlights.includes('pay')}>
                <NumberInput
                  value={row[tableMD.columns.pay.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.pay.name]: v })}
                  precision={2}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="mFond" highlighted={highlights.includes('mFond')}>
                <NumberInput
                  value={row[tableMD.columns.mFond.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.mFond.name]: v })}
                  precision={2}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="yFond" highlighted={highlights.includes('yFond')}>
                <NumberInput
                  value={row[tableMD.columns.yFond.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.yFond.name]: v })}
                  precision={2}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
};

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.stateUnit.name]: referencePropType,
    [tableMD.columns.yFond.name]: PropTypes.number,
    [tableMD.columns.mFond.name]: PropTypes.number,
    [tableMD.columns.pay.name]: PropTypes.number,
    [tableMD.columns.add.name]: PropTypes.number,
    [tableMD.columns.sol.name]: PropTypes.number,
    [tableMD.columns.num.name]: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  FI: referencePropType.isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSR: PropTypes.func.isRequired,
  //
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
