import React, {
  useState, useMemo, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container, Dropdown, Row, Col, Button,
} from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import enums from '../../../../constants/meta/enums';
import MonthGrid from './styles';
import MonthButton from './monthButton';
import DateInput from '../dates';

function getPeriodValue(periodType) {
  const sd = new Date();
  sd.setHours(0, 0, 0, 0);
  const ed = new Date();
  ed.setHours(23, 59, 59, 0);
  switch (periodType) {
  case enums.PeriodSelection.Custom.name:
    break;
  case enums.PeriodSelection.FromBeginningOfThisYear.name:
    sd.setMonth(0, 1);
    break;
  case enums.PeriodSelection.ThisMonth.name:
    sd.setDate(1);
    ed.setMonth(ed.getMonth() + 1, 1);
    ed.setDate(0);
    break;
  case enums.PeriodSelection.ThisQuarter.name:
    sd.setMonth(Math.floor(sd.getMonth() / 3) * 3, 1);
    ed.setMonth((Math.floor(sd.getMonth() / 3) + 1) * 3, 1);
    ed.setDate(0);
    break;
  case enums.PeriodSelection.ThisWeek.name:
    sd.setDate(sd.getDate() - sd.getDay() + 1);
    ed.setDate(sd.getDate() + 6);
    break;
  case enums.PeriodSelection.ThisYear.name:
    sd.setMonth(0, 1);
    ed.setMonth(12, 1);
    ed.setDate(0);
    break;
  case enums.PeriodSelection.Today.name:
    sd.setHours(0, 0, 0);
    ed.setHours(23, 59, 59);
    break;
  default:
    throw new Error('Unknow period type');
  }
  sd.setMinutes(sd.getMinutes() - sd.getTimezoneOffset());
  ed.setMinutes(ed.getMinutes() - ed.getTimezoneOffset());
  return {
    startDate: sd.valueOf(),
    endDate: ed.valueOf(),
  };
}

const PeriodForm = ({ value, onChange }) => {
  const startDate = useMemo(
    () => {
      const D = new Date(value.startDate);
      D.setMinutes(D.getMinutes() + D.getTimezoneOffset());
      return D;
    },
    [value],
  );
  const endDate = useMemo(
    () => {
      const D = new Date(value.endDate);
      D.setMinutes(D.getMinutes() + D.getTimezoneOffset());
      return D;
    },
    [value],
  );

  const currentDate = new Date();
  const [currentYear, setCurrentYear] = useState(!value.startDate && !value.endDate
    ? currentDate.getFullYear()
    : Math.min(startDate.getFullYear(), endDate.getFullYear()));
  const currMode = useRef(0);

  const variants = useMemo(
    () => [
      ...Object.values(enums.PeriodSelection)
        .filter((v) => v.name !== enums.PeriodSelection.Custom.name)
        .map((v) => ({
          ...v,
          ...getPeriodValue(v.name),
        })),
      {
        ...enums.PeriodSelection.Custom,
        startDate: value.startDate,
        endDate: value.endDate,
      },
    ],
    [value.endDate, value.startDate],
  );
  const years = useMemo(
    () => [currentYear - 1, currentYear, currentYear + 1],
    [currentYear],
  );
  const months = useMemo(
    () => [...Array(12)].map((_, m) => {
      const sd = new Date(currentYear, m, 1, 0, 0, 0, 0);
      const ed = new Date(currentYear, m + 1, 0, 23, 59, 59, 0);
      sd.setMinutes(sd.getMinutes() - sd.getTimezoneOffset());
      ed.setMinutes(ed.getMinutes() - ed.getTimezoneOffset());

      return {
        key: m,
        startDate: sd.valueOf(),
        endDate: ed.valueOf(),
        label: sd.toLocaleDateString('uk', { month: 'short' }),
      };
    }),
    [currentYear],
  );

  const onMonthClick = useCallback(
    (e, v) => {
      switch (currMode.current) {
      case 0: // первый клик
        onChange(e, { ...value, startDate: v.startDate, endDate: v.endDate });
        currMode.current = 1;
        break;
      case 1: // воторой клик
        onChange(e, { ...value, endDate: v.endDate });
        currMode.current = 0;
        break;
      default:
        throw new Error('Unknow mode');
      }
    },
    [onChange, value],
  );

  const onVariantChange = useCallback(
    (e, v) => {
      onChange(e, {
        startDate: v.startDate,
        endDate: v.endDate,
        variant: v.name,
      });
    },
    [onChange],
  );

  const currentVariant = useMemo(
    () => {
      const c = variants.filter((v) => v.name !== enums.PeriodSelection.Custom.name
        && v.startDate === value.startDate && v.endDate === value.endDate);
      if (c.length) return c[0].name;
      return enums.PeriodSelection.Custom.name;
    },
    [value, variants],
  );

  return (
    <Container>
      <Row className="mb-2">
        <Col>
          <Dropdown>
            <DropdownToggle variant="outline-secondary" id="period-variant-toggler" className="w-100">
              {enums.PeriodSelection[currentVariant].label}
            </DropdownToggle>
            <Dropdown.Menu>
              {variants.map((v) => (
                <Dropdown.Item
                  key={v.name}
                  active={v.name === value.variant}
                  onSelect={(e) => onVariantChange(e, v)}
                >
                  {v.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col>
          <DateInput
            value={value.startDate}
            onChange={(e, v) => onChange(e, { ...value, startDate: v })}
          />
        </Col>
        <Col>
          <DateInput
            value={value.endDate}
            onChange={(e, v) => onChange(e, { ...value, endDate: v })}
            endOfDay
          />
        </Col>
      </Row>
      <Row className="mt-2 text-center">
        {years.map((y) => (
          <Col key={y}>
            <Button
              variant="outline-secondary"
              className="w-100"
              onClick={() => setCurrentYear(y)}
            >
              {y}
            </Button>
          </Col>
        ))}
      </Row>
      <MonthGrid className="mt-2">
        {months.map((m) => (
          <MonthButton
            key={m.key}
            onClick={onMonthClick}
            value={m}
            startDate={value.startDate}
            endDate={value.endDate}
          >
            {m.label}
          </MonthButton>
        ))}
      </MonthGrid>

    </Container>
  );
};

PeriodForm.propTypes = {
  value: PropTypes.shape({
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    variant: PropTypes.oneOf(Object.values(enums.PeriodSelection).map((v) => v.name)),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PeriodForm;
