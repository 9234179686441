import {
  defaultColumns,
  defaultOrder,
  hierarchyTypes,
  reqListerColumns,
  directions,
} from '../common';

/**
 * @const
 * @type {object}
 */
const columns = {
  ...defaultColumns,
  tarif1: { label: 'Тарифний розряд (не використовується)', name: 'ТарифныйРазряд1', visible: false },
  tarif: { label: 'Тарифний розряд', name: 'ТарифныйРазряд', visible: true },
  post: {
    name: 'Должность',
    label: 'Посада',
    visible: true,
  },
  owner: { label: 'Розпорядник', name: 'Владелец', visible: true },
  category: { label: 'Категорія', name: 'Категория', visible: false },
  units: { label: 'Підрозділ', name: 'Подразделения', visible: true },
  text2: { label: 'Текст2', name: 'Текст2', visible: false },
  text1: { label: 'Текст1', name: 'Текст1', visible: false },
  text3: { label: 'Текст3', name: 'Текст3', visible: false },
  text4: { label: 'Текст4', name: 'Текст4', visible: false },
  sorting: { label: 'Код (для сортування)', name: 'Сорт', visible: true },
  m1: { label: '1-й місяць', name: 'Мес1', visible: false },
  m2: { label: '2-й місяць', name: 'Мес2', visible: false },
  m3: { label: '3-й місяць', name: 'Мес3', visible: false },
  m4: { label: '4-й місяць', name: 'Мес4', visible: false },
  m5: { label: '5-й місяць', name: 'Мес5', visible: false },
  m6: { label: '6-й місяць', name: 'Мес6', visible: false },
  m7: { label: '7-й місяць', name: 'Мес7', visible: false },
  m8: { label: '8-й місяць', name: 'Мес8', visible: false },
  m9: { label: '9-й місяць', name: 'Мес9', visible: false },
  m10: { label: '10-й місяць', name: 'Мес10', visible: false },
  m11: { label: '11-й місяць', name: 'Мес11', visible: false },
  m12: { label: '12-й місяць', name: 'Мес12', visible: false },
  fizik: { label: 'Це фізична особа', name: 'флФизОсоба', visible: false },
  scientic: { label: 'Освіта', name: 'Образование', visible: false },
  ped: { label: 'Педагогічне звання', name: 'ПедагогическоеЗвание', visible: false },
  individuals: { label: 'Фізична особа', name: 'ФизическиеЛицаОрганизаций', visible: false },
  flVacancy: { label: 'Вакансія/Декрет', name: 'флВакансия', visible: false },
  family: { label: 'Сімейний статус', name: 'СемейноеПоложение', visible: false },
  branches: { label: 'Галузь', name: 'Отрасль', visible: false },
  note: { label: 'Примітка', name: 'Примечание', visible: true },
  dEnd: {
    label: 'Дата закінчення дії ш.о.', name: 'ДатаОкончания', visible: true, type: 'date',
  },
  blockSpan: { label: 'Дана картка присутня в затверджених тарифікаційних списках. Можливість редагування обмежена.', name: 'ЗаблокированоНадпись', visible: false },
  blockSpanVisible: { label: 'ЗаблокированоНадписьВидимость', name: 'ЗаблокированоНадписьВидимость', visible: false },
  ownerAvialable: { label: 'ЭлементыВладелецДоступность', name: 'ЭлементыВладелецДоступность', visible: false },
  nameReadOnly: { label: 'ЭлементыНаименованиеТолькоПросмотр', name: 'ЭлементыНаименованиеТолькоПросмотр', visible: false },
  text1ReadOnly: { label: 'ЭлементыТекст1ТолькоПросмотр', name: 'ЭлементыТекст1ТолькоПросмотр', visible: false },
  text2ReadOnly: { label: 'ЭлементыТекст2ТолькоПросмотр', name: 'ЭлементыТекст2ТолькоПросмотр', visible: false },
  text3ReadOnly: { label: 'ЭлементыТекст3ТолькоПросмотр', name: 'ЭлементыТекст3ТолькоПросмотр', visible: false },
  text4ReadOnly: { label: 'ЭлементыТекст4ТолькоПросмотр', name: 'ЭлементыТекст4ТолькоПросмотр', visible: false },
  tarifsReadOnly: { label: 'ЭлементыТрудоваяДеятельностьТолькоПросмотр', name: 'ЭлементыТрудоваяДеятельностьТолькоПросмотр', visible: false },
  page1ReadOnly: { label: 'Элементыстр1ТолькоПросмотр', name: 'Элементыстр1ТолькоПросмотр', visible: false },
  page2ReadOnly: { label: 'Элементыстр2ТолькоПросмотр', name: 'Элементыстр2ТолькоПросмотр', visible: false },
};

const tables = {
  tarifs: {
    label: 'Періоди дії штатної одиниці',
    name: 'ТрудоваяДеятельность',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      dStart: { label: 'Дата початку бюджетного періоду', name: 'ДатаНачала' },
      dEnd: { label: 'Дата закінчення бюджетного періоду', name: 'ДатаОкончания' },
      note: { label: 'Примітка (дата та № наказу та ін. нормативних актів)', name: 'Примітка' },
    },
  },
  vocations: {
    label: 'Відпустка',
    name: 'Отпуска',
    columns: {
      num: { label: '№', name: 'НомерСтроки' },
      period: { label: 'Період', name: 'Период' },
      fiz: { label: 'Фізична особа', name: 'ФизическоеЛицо' },
      daysV: { label: 'Кількість днів відпустки', name: 'ДнейОтпуска' },
    },
  },
};

const footer = { ...columns };
/**
 * @const
 */
export const stateUnits = {
  label: 'Штатні одиниці',
  name: 'stateUnits',
  backendName: 'ШтатныеЕдиницы',
  defaultOrder: [
    {
      column: defaultColumns.isGroup.name,
      direction: directions.descending,
    },
    ...defaultOrder,
  ],
  columns,
  tables,
  footer,
  frontend: 'cat/stateUnits',
  listColumns: [
    ...new Set([
      'sorting',
      ...reqListerColumns,
      'post',
      'tarif',
      'units',
      'owner',
      'dEnd',
      'note',
    ]),
  ],
  hierarchy: true,
  hierarchyType: hierarchyTypes.foldersNItems,
};

export default stateUnits;
