import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../constants/meta/documents/calcTarifList';

import BudgetFOUserEditorRow from '../../_common/BudgetFOUserEditorRow';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import EditorCollapse from '../../../../components/bootstrap_components/editorCollapse';
import { AppContext } from '../../../../providers/authProvider';

const CalcTarifListEditor = ({ data, actions, permissions }) => {
  const appContext = useContext(AppContext);
  const { sessionOptions } = appContext;
  const FI = data[md.columns.csu.name];
  const FiOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );

  const readOnly = !permissions.canChange;
  return (
    <EditorCollapse>
      {sessionOptions.is_admin && (
        <BudgetFOUserEditorRow
          onChange={actions.onChange}
          data={data}
          readOnly={readOnly}
        />
      )}
      <Row>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.number.label}
            value={data[md.columns.number.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.number.name]: value,
            })}
            readOnly
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.date.label}
            value={data[md.columns.date.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.date.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`recount-${data.id}`}
            label={md.columns.recount.label}
            value={data[md.columns.recount.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.recount.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.changeDate.label}
            value={data[md.columns.changeDate.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.changeDate.name]: value,
              });
              actions.onSR('CHANGEDATE_ON_CHANGE');
            }}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`detCh-${data.id}`}
            label={md.columns.detCh.label}
            value={data[md.columns.detCh.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.detCh.name]: value,
            })}
            readOnly={(readOnly) || (!data[md.columns.detChAvailable.name])}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.unit.label}
            value={data[md.columns.unit.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.unit.name]: value,
            })}
            modelType="cat"
            modelName="units"
            readOnly={readOnly}
            filter={FiOwnerFilter}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.kbp.label}
            value={data[md.columns.kbp.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.kbp.name]: value,
              });
              actions.onSR('KBP_ON_CHANGE');
            }}
            modelType="cat"
            modelName="kbpClassifier"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.branches.label}
            value={data[md.columns.branches.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.branches.name]: value,
              });
              actions.onSR('BRANCHES_ON_CHANGE');
            }}
            modelType="cat"
            modelName="branches"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.fond.label}
            value={data[md.columns.fond.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.fond.name]: value,
            })}
            modelType="cat"
            modelName="elementFond"
            readOnly={readOnly}
            filter={FiOwnerFilter}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`isApproved-${data.id}`}
            label={md.columns.isApproved.label}
            value={data[md.columns.isApproved.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.isApproved.name]: value,
            })}
            readOnly
          />
        </Col>
      </Row>
    </EditorCollapse>
  );
};

CalcTarifListEditor.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default CalcTarifListEditor;
