import React, {
  useState, useMemo,
} from 'react';

import ListerFactory from '../../../newLister';
import { DocTableListerFactory } from '../../../newLister/tableComponent';

import CommandPanel from './commandpanel';

const FOLister = ListerFactory({
  modelType: 'doc',
  modelName: 'calcNightAndHoliday',
  viewType: 'lister',
})(DocTableListerFactory({
  CommandPanel,
}));

// (1), (2), (3), (0)
// const filterValues = Object.values({
//   Actual: { name: 'Actual', label: 'Актуальні' },
//   CloseThisYear: { name: 'CloseThisYear', label: 'Закриті в поточному році' },
//   CloseLAstYear: { name: 'CloseLAstYear', label: 'Закриті в минулому році (архів)' },
//   All: { name: 'All', label: 'Всі' },
// })
//   .map((r) => ({ value: r.name, label: r.label }));

const Lister = () => {
  // eslint-disable-next-line no-unused-vars
  const [fValue, setFValue] = useState(1);
  const flt = useMemo(
    () => ([{
      fieldName: 'ТипРеестра',
      value: fValue,
    }]),
    [fValue],
  );
  return (
    <>
      {/* <FilteredTabs
      values={filterValues}
      value={fValue}
      onChange={(e, v) => setFValue(v)} /> */}
      <FOLister filter={flt} />
    </>
  );
};

export default Lister;
