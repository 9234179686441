import React, { forwardRef } from 'react';
import DocListerCommandPanel from '../../../newLister/tableComponent/commandpanel/doc_panel';

// const ButtonApprove = ({ id, actions }) => (
//   <DefaultCPButton
//     onClick={() => actions.onSR('ON_APPROVE', { id })}
//   >
//     Aprove
//   </DefaultCPButton>
// );
//
// const CommandPanel = forwardRef((_, ref) => {
//   const { selectedRows, actions } = useContext(ListerContext);
//   return (
//     <DocListerCommandPanel
//       ref={ref}
//     >
//       <ButtonApprove
//         id={selectedRows}
//         actions={actions}
//       />
//     </DocListerCommandPanel>
//   );
// });

const CommandPanel = forwardRef((_, ref) => (
  <DocListerCommandPanel
    ref={ref}
  />
));

export default CommandPanel;
