export const fieldTypes = {
  Auto: 'Auto', // Автополе
  SelectedFieldGroup: 'SelectedFieldGroup', // Группа выбранных полей
  SelectedField: 'SelectedField', // Выбранное поле
  FilterItem: 'FilterItem', // Элемент отбора
  FilterItemGroup: 'FilterItemGroup', // Группа элементов отбора
  OrderItem: 'OrderItem', // Элемент сортировки
  Group: 'Group', // Группировка компоновки данных
  GroupField: 'GroupField', // Поле группировки компоновки данных
  // ТаблицаКомпоновкиДанных
  // ДиаграммаКомпоновкиДанных
  // НастройкиВложенногоОбъектаКомпоновкиДанных
};
export default fieldTypes;
