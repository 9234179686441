import {
  ItemPicker, NumberInput, YearInput, SelectorInput,
  // eslint-disable-next-line import/named
  StringInput, TextInput, RangeDatePicker, DateInput,
} from '../controls';

import withEditorControl, { EditorControlDefaultProps, EditorControlPropTypes } from './editorControl';
import EditorCheckbox from './checkbox';
import EditorRadio from './radio';

export const EditorDateInput = withEditorControl(DateInput);
EditorDateInput.propTypes = {
  ...EditorControlPropTypes,
  ...DateInput.propTypes,
};

EditorDateInput.defaultProps = {
  ...EditorControlDefaultProps,
  ...DateInput.defaultProps,
};

export const EditorPeriodInput = withEditorControl(RangeDatePicker);
RangeDatePicker.propTypes = {
  ...EditorControlPropTypes,
  ...RangeDatePicker.propTypes,
};

RangeDatePicker.defaultProps = {
  ...EditorControlDefaultProps,
  ...RangeDatePicker.defaultProps,
};

export const EditorItemPicker = withEditorControl(ItemPicker);
EditorItemPicker.propTypes = {
  ...EditorControlPropTypes,
  ...ItemPicker.propTypes,
};

EditorItemPicker.defaultProps = {
  ...EditorControlDefaultProps,
  ...ItemPicker.defaultProps,
};

export const EditorNumberInput = withEditorControl(NumberInput);
EditorNumberInput.propTypes = {
  ...EditorControlPropTypes,
  ...NumberInput.propTypes,
};

EditorNumberInput.defaultProps = {
  ...EditorControlDefaultProps,
  ...NumberInput.defaultProps,
};

export const EditorYearInput = withEditorControl(YearInput);
EditorYearInput.propTypes = {
  ...EditorControlPropTypes,
  ...YearInput.propTypes,
};

EditorYearInput.defaultProps = {
  ...EditorControlDefaultProps,
  ...YearInput.defaultProps,
};

export const EditorSelectorInput = withEditorControl(SelectorInput);
EditorSelectorInput.propTypes = {
  ...EditorControlPropTypes,
  ...SelectorInput.propTypes,
};

EditorSelectorInput.defaultProps = {
  ...EditorControlDefaultProps,
  ...SelectorInput.defaultProps,
};

export const EditorStringInput = withEditorControl(StringInput);
EditorStringInput.propTypes = {
  ...EditorControlPropTypes,
  ...StringInput.propTypes,
};

EditorStringInput.defaultProps = {
  ...EditorControlDefaultProps,
  ...StringInput.defaultProps,
};

export const EditorTextInput = withEditorControl(TextInput);
EditorTextInput.propTypes = {
  ...EditorControlPropTypes,
  ...TextInput.propTypes,
};

EditorTextInput.defaultProps = {
  ...EditorControlDefaultProps,
  ...TextInput.defaultProps,
};

export { EditorCheckbox, EditorRadio };

export const EditorControls = {
  DateInput: EditorDateInput,
  ItemPicker: EditorItemPicker,
  NumberInput: EditorNumberInput,
  YearInput: EditorYearInput,
  SelectorInput: EditorSelectorInput,
  CheckboxInput: EditorCheckbox,
  RadioInput: EditorRadio,
  StringInput: EditorStringInput,
  TextInput: EditorTextInput,
  PeriodInput: EditorPeriodInput,
};
