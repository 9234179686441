import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput, StringInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/personCard';
import TableRow from '../../../../../newEditor/tablePart/tablerow';

import TableRowCell from '../../../../../newEditor/tablePart/tableRowCell';

const tablename = 'pedLoad1011';

const tableMD = md.tables[tablename];

const TPRow = ({
  row, active, rowIndex, activeCol,
  onChange, readOnly, highlights,
}) => {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col
            className="d-flex"
          >
            <Col>
              <TableRowCell column="calcs" highlighted={highlights.includes('calcs')}>
                <ItemPicker
                  value={row[tableMD.columns.calcs.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.calcs.name]: v })}
                  modelType="cat"
                  modelName="pedagogicalLoad"
                  noHierarchy
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="hours" highlighted={highlights.includes('hours')}>
                <NumberInput
                  value={row[tableMD.columns.hours.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.hours.name]: v })}
                  precision={2}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="fact" highlighted={highlights.includes('fact')}>
                <NumberInput
                  value={row[tableMD.columns.fact.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.fact.name]: v })}
                  precision={2}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="postr" highlighted={highlights.includes('postr')}>
                <NumberInput
                  value={row[tableMD.columns.postr.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.postr.name]: v })}
                  precision={10}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col>
              <TableRowCell column="classes" highlighted={highlights.includes('classes')}>
                <StringInput
                  value={row[tableMD.columns.classes.name]}
                  readOnly
                />
              </TableRowCell>
            </Col>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
};

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.hours.name]: PropTypes.number,
    [tableMD.columns.fact.name]: PropTypes.number,
    [tableMD.columns.postr.name]: PropTypes.number,
    [tableMD.columns.classes.name]: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
