import {
  useCallback, useMemo, useRef, useState,
} from 'react';
import definition from '../../../constants/meta';
import api from '../../../api/req';
import { generateVisibleItems } from '../../../common/editorMappers';
// import { newFile } from '../../notifier';

const getMaxId = (t) => t.reduce((R, r, k) => (Number(k.slice(1)) > R ? Number(k.slice(1)) : R), 0);
const SELECTED_KEY = '_SELECTED';

const useEditor = (reportParams) => {
  const {
    modelName,
    modelType,
  } = useCallback(() => reportParams, [reportParams]);

  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const currentData = useRef(null);
  const [data, setData] = useState({
    current: {},
    history: {
      data: [], pointer: null,
    },
    autoExecute: false,
    modelType,
    modelName,
    headerForm: [],
    tables: [],
    result: [],
    dataCompositionSettings: [],
    isProcessing: false,
    isChanged: false,
    isValidated: false,
    hasError: false,
    errorMsg: null,
    disabledFields: [],
  });

  currentData.current = data;
  const controller = useMemo(() => new AbortController(), []);
  /* SERVER REQUEST ACTIONS */

  const serverRequestError = useCallback((e) => {
    setErr({
      errorMsg: e,
      model: modelName,
    });

    setData({
      ...data,
      hasError: true,
      isProcessing: false,
      errorMsg: err,
    });
  }, [data, err, modelName]);

  const dcSettingsMapper = (fieldName, response, oldValue = null) => {
    const getIndexedCollection = (collection) => new Map(collection);
    const getAvailableCollection = (DCS, section) => {
    // Если доступные поля не пришли - используем старые
      if (!DCS[section] && !!oldValue) return oldValue[section].items;
      return getIndexedCollection(DCS[section]);
    };

    let GAV = new Map();
    let FAV = new Map();
    let OAV = new Map();
    let SAV = new Map();
    let CAFAV = new Map();
    let Group = new Map();
    let Filter = new Map();
    let Order = new Map();
    let Selection = new Map();
    let ConditionalAppearance = new Map();

    const proSet = (obj, parent = null) => {
      const mapKeys = getIndexedCollection(obj);
      // eslint-disable-next-line no-unused-vars
      const compileItem = (item) => {
      // Устнаовть парент по таблице перекодировки
        let newItem = new Map();
        if (item.get('Parent') === null) {
          newItem = item;
        } else if (item.get('Parent') === '') {
          newItem = item.set('Parent', null);
        } else {
          newItem = item.set('Parent', mapKeys.get(item.get('Parent'), parent));
        }

        // Проработать таблицу
        if (newItem.get('Type', '') === 'Table') {
        // console.log('columns', newItem.get('Columns').toJS());
        // console.log(proSet(newItem.get('Columns')).toJS())
          newItem = newItem.set('Columns', proSet(newItem.get('Columns')));
          // console.log('rows');
          newItem = newItem.set('Rows', proSet(newItem.get('Rows')));
        }
        return newItem;
      };
      // console.log(tempO.mapEntries(([k, v]) => [mapKeys.get(k), compileItem(v)]).toJS());
      return obj;
      // return indexTable(tempO.entries(([k, v]) => [mapKeys.get(k), compileItem(v)]));
    };

    if (response[fieldName] !== undefined) {
      GAV = getAvailableCollection(response[fieldName], 'GroupAvailableFields');
      FAV = getAvailableCollection(response[fieldName], 'FilterAvailableFields');
      OAV = getAvailableCollection(response[fieldName], 'OrderAvailableFields');
      SAV = getAvailableCollection(response[fieldName], 'SelectionAvailableFields');
      CAFAV = getAvailableCollection(response[fieldName], 'ConditionalAppearanceFilterAvailableFields');
      Group = proSet(response[fieldName].Group);
      Filter = proSet(response[fieldName].Filter);
      Order = proSet(response[fieldName].Order);
      Selection = proSet(response[fieldName].Selection);
      ConditionalAppearance = proSet(response[fieldName].ConditionalAppearance)
        .map((cRow) => cRow.set('Filter', proSet(cRow.get('Filter', new Map()))));
    }

    return new Map({
      Group,
      Filter,
      Order,
      Selection,
      ConditionalAppearance,

      GroupAvailableFields: new Map({
        items: GAV,
        visibleItems: generateVisibleItems(GAV),
      }),

      FilterAvailableFields: new Map({
        items: FAV,
        visibleItems: generateVisibleItems(FAV),
      }),
      OrderAvailableFields: new Map({
        items: OAV,
        visibleItems: generateVisibleItems(OAV),
      }),
      SelectionAvailableFields: new Map({
        items: SAV,
        visibleItems: generateVisibleItems(SAV),
      }),
      ConditionalAppearanceFilterAvailableFields: new Map({
        items: CAFAV,
        visibleItems: generateVisibleItems(CAFAV),
      }),
    });
  };
  const serverReqDone = useCallback(async (promiseResponse) => {
  /**
   * @const
   */
    // const store = getState();
    //
    // const node = store.get(`rep/${meta.backendName}/reportEditor`, new Map());

    const { autoExecute } = data;

    const response = await promiseResponse;
    const DCS = dcSettingsMapper('DataCompositionSettings', response, data.dataCompositionSettings);

    const item = {
      ...response,
      dataCompositionSettings: DCS,
      model: modelName,
    };

    // dispatch({
    //   type: reportEditorActions.SR_DONE,
    //   payload: item,
    // });

    setLoading(false);
    setData({
      ...item,
      isProcessing: false,
    });

    //     payload.tables.keySeq()
    // .reduce(
    //   (r, table) => r.set(`tables/${table}`, payload.item.tables.get(table)),
    //   state
    //     .set('isProcessing', false)
    //     .set('headerForm', payload.item.headerForm || null),

    if (autoExecute) {
      // eslint-disable-next-line no-use-before-define
      generate();
    }
    // eslint-disable-next-line no-use-before-define
  }, [data, generate, modelName]);

  const processServerReq = useCallback((method, extra = null) => {
    const { signal } = controller;
    /**
   * @const
   */

    const modelDef = definition[modelType][modelName];

    setData({
      ...data,
      isProcessing: true,
      hasError: false,
    });

    const DCSNode = data.dataCompositionSettings;
    const DCS = {
      Group: DCSNode.Group,
      Filter: DCSNode.Filter,
      Order: DCSNode.Order,
      Selection: DCSNode.Selection,
      ConditionalAppearance: DCSNode.ConditionalAppearance,
    };

    const itemData = {
      ...data,
      dataCompositionSettings: DCS,
    };

    api
      .post$(`${modelType}/${modelDef.backendName}/SR`, {
        signal, method, item: itemData, ...extra,
      })
      .then((d) => {
        if (d.ok) {
          const dJSON = d.json();
          // eslint-disable-next-line no-use-before-define
          serverReqDone(dJSON);
          if (method === 'reportToFile') {
            dJSON.then((ff) => {
              // eslint-disable-next-line no-unused-vars
              const f = ff.fileFromReport;
              // newFile(f.filename, f.content, f.type, f.description);
            });
          }
        } else if (d.status === 422) {
          d.json().then(
          // eslint-disable-next-line no-underscore-dangle
            (dt) => serverRequestError(dt._Error),
          );
        } else {
          serverRequestError(`${d.status} ${d.statusText}`);
        }
      })
      .catch((e) => {
        if (!signal.aborted) {
          serverRequestError(e);
        }
      });
  }, [controller, data, modelName, modelType, serverReqDone, serverRequestError]);

  const generate = useCallback(() => {
    setData({
      ...data,
      autoExecute: false,
    });
    processServerReq('generate');
  }, [data, processServerReq]);

  const cancelRequest = useCallback(() => {
    setData({
      ...data,
      isProcessing: false,
    });
    controller.abort();
  }, [controller, data]);

  const reportToFile = useCallback((typeFile) => {
    setData({
      ...data,
      autoExecute: false,
    });
    processServerReq('reportToFile', { format: typeFile });
  }, [data, processServerReq]);

  /* INIT ACTION */
  /**
 * @param _type {string}
 * @param model {string}
 * @param mapInitialOptions {object}
 * @param mapStore {function}
 * @param autoExecute {bool}
 * @return {function(*)}
 */
  const init = (
    _type,
    model,
    mapInitialOptions = null,
    autoExecute = false,
  ) => {
    if (mapInitialOptions) {
      const initOptions = Object.keys(data).reduce((r, key) => ({
        ...r,
        [mapInitialOptions[key]]: data.get(key),
      }), {});
      setData({
        ...initOptions,
        autoExecute,
      });
    }
  };

  // const changeFieldHandler = (dispatch, getState) => {}
  const changeField = useCallback((path, value) => {
  /**
   * @const
   * @type {bool}
   */

    currentData.current.headerForm[path] = value;

    setData({
      ...data,
      current: currentData.current,
    });
  }, [data]);

  const activateTableRow = useCallback((tableName, rowId, isMutiple = false) => {
  /**
   * @const
   * @type {bool}
   */
    /**
   * @const
   */
    if (isMutiple) {
      currentData.current[`tables/${tableName}`].rowId.IS_ACTIVE = !currentData.current[`tables/${tableName}`].rowId.IS_ACTIVE;
    } else {
      currentData.current[`tables/${tableName}`].rowId.IS_ACTIVE = !data[`tables/${tableName}`].rowId.IS_ACTIVE;
    }
    setData({
      ...data,
      tables: currentData.current,
    });
  }, [data]);

  const fillTable = useCallback((tableName, path, value) => {
  /**
   * @const
   */

    const table = data[`tables/${tableName}`];

    const newTable = table.map((row) => {
      // eslint-disable-next-line no-param-reassign
      row[path] = value;
      return row;
    });

    // dispatch({
    //   type: reportEditorActions.TABLE_FILL,
    //   payload: {
    //     name: tableName,
    //     items: newTable,
    //     model: storePathParam(getState()).name,
    //   },
    // });

    setData({
      ...data,
      [`tables/${tableName}`]: newTable,
      isChanged: true,
    });
    // .set(
    //   `tables/${payload.name}`,
    //   payload.items,
    // );
  }, [data]);

  const DCSelectField = useCallback((path, field, multiple = false) => {
    // eslint-disable-next-line max-len
    // const oldTable = getState().getIn([`rep/${storePathParam(getState()).name}/reportEditor`, ...path]);
    const oldTable = data[path];
    const newTable = multiple ? oldTable : oldTable.map((_r) => _r.delete(SELECTED_KEY));
    const newTable2 = field
      ? newTable[field][SELECTED_KEY][!newTable[field][SELECTED_KEY]]
      : newTable;
    if (!oldTable.equals(newTable2)) {
      // dispatch({
      //   type: reportEditorActions.CHANGE_PROPERTY,
      //   payload: {
      //     path,
      //     value: newTable2,
      //     model: storePathParam(getState()).name,
      //   },
      // });
      // return state.set('isChanged', true).setIn(payload.path, payload.value);
      setData({
        ...data,
        isChanged: true,
        [path]: newTable2,
      });
    }
  }, [data]);

  const DCToggleFieldDone = useCallback((path) => {
    // dispatch({
    //   type: reportEditorActions.CHANGE_PROPERTY,
    //   payload: {
    //     path: [...path, 'visibleItems'],
    //     value: newVi2,
    //     model: storePathParam(getState()).name,
    //   },
    // });
    setData({
      ...data,
      isChanged: true,
      [path]: data[path].items,
    });
  }, [data]);

  const DCToggleField = useCallback((path, field) => {
    const modelDef = definition[modelType][modelName];

    const cVItem = data[path].visibleItems[field];
    if (cVItem.itemsCount && !cVItem.TOGGLED_KEY && !cVItem.Items) {
      api.post$(`${modelType}/${modelDef.backendName}/getAvailableParent`, {
        field,
        section: path[1],
      })
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((dt) => {
              const fPath = field.split('.')
                .reduce((R, p) => [...R, R.length ? `${R[R.length - 1]}.${p}` : p], [])
                .reduce((R, p) => [...R, p, 'Items'], []);
              // dispatch({
              //   type: reportEditorActions.CHANGE_PROPERTY,
              //   payload: {
              //     path: [...path, 'items', ...fPath],
              //     value: new OrderedMap(dt.Items).map((i) => fromJS(i)),
              //     model: modelName,
              //   },d
              // });
              const inclPath = `${path}.items.${fPath}`;
              setData({
                ...data,
                isChanged: true,
                [inclPath]: dt.Items,
              });
              DCToggleFieldDone(path, field);
            });
          } else {
            throw new Error(`${resp.status} ${resp.statusText}`);
          }
        })
        .catch((e) => serverRequestError(e.message));
    } else {
      DCToggleFieldDone(path, field);
    }
  }, [DCToggleFieldDone, data, modelName, modelType, serverRequestError]);

  const DCDeleteField = useCallback((path, id = null) => {
    const KEY = '_SELECTED';

    const deleteBroken = (table) => table.filter((row) => !row.Parent || table.has(row.Parent));
    let newTable1;
    if (!id) {
      newTable1 = data[path].filter((row) => !row[KEY]);
    } else {
      newTable1 = data[path].filter((row, rId) => rId !== id);
    }
    let newTable2;
    let tablesEqual = false;
    while (!tablesEqual) {
      newTable2 = deleteBroken(newTable1);
      tablesEqual = newTable2.equals(newTable1);
      if (!tablesEqual) {
        newTable1 = newTable2;
      }
    }

    setData({
      ...data,
      isChanged: true,
      [path]: newTable2,
    });
  }, [data]);

  /**
 * Лобавление полей в раздел
 * @param path {[string, string]} - Узел стора куда добвляется поле(я).
 * @param fields - Сами поля, которые добавляются
 * @param parentId - Кто родитель
 * @param index - Куда
 * @return {function(*, *)}
 * @constructor
 */
  const DCAddField = (path, fields, parentId, index) => {
    const tableName = '1'; // ???
    const table = data[`tables/${tableName}`];
    const maxId = getMaxId(table);
    // const remapParents = table.mapEntries(([k, v]) => [k, k < index ? k : k + fields.size]);
    const before = table.slice(0, index);
    const after = table.slice(index);
    const newFields = fields.entries(([v], i) => [
      `_${maxId + i + 1}`,
      v
        .set('Use', true).set('Parent', parentId),
    ]).setIn([`_${maxId + fields.size}`, SELECTED_KEY], true);

    const newTable = before
      .merge(newFields)
      .merge(after);
    // console.log(indexGTable(newTable).toJS());
    // dispatch({
    //   type: reportEditorActions.CHANGE_PROPERTY,
    //   payload: {
    //     path,
    //     value: newTable,
    //     model: storePathParam(getState()).name,
    //   },
    // });

    setData({
      ...data,
      isChanged: true,
      [path]: newTable,
    });
  };

  const getFile = useCallback((paramGetter) => {
  /**
   * @const
   */
    const modelDef = definition[modelType][modelName];

    api
      .post$(`${modelType}/${modelDef.backendName}/GetFile`, { item: data.current, ...paramGetter })
      .then((r) => {
        if (r.ok) {
          r.json().then(() => {
            // newFile(d.filename, d.content, d.type, d.description);
          });
        } else {
          serverRequestError(r.status);
        }
      })
      .catch((e) => {
        serverRequestError(e);
      });
  }, [data, modelName, modelType, serverRequestError]);

  /**
 * Перемещение полей внутри одного раздела
 * @param path {string} - Узел стора куда добвляется поле(я).
 * @param fields - Сами поля, которые добавляются
 * @param from - отклуда перемещаем
 * @param to - куда перемещаем
 * @param newParentId - Новый Ролитель
 * @return {function(*, *)}
 * @constructor
 */
  // const DCMoveField = (path, fields, from, to, newParentId) => {
  //   const table = data.current;
  //   const min = Math.min(from, to);
  //   const max = Math.max(from, to);
  //   const before = table.slice(0, min - 1);
  //
  //   const t1 = from < to
  //     ? table.slice((from + fields.size) - 1, max)
  //     : table.slice(from - 1, (from + fields.size) - 1);
  //   const t2 = from < to
  //     ? table.slice(from - 1, (from + fields.size) - 1)
  //     : table.slice(min - 1, (from + fields.size) - 2);
  //   const after = table.slice(max);
  //   const newTable = before
  //     .merge(t1.map((r, k) => {
  //       if (        fields.has(k)) {
  //         r.Parent = newParentId;
  //       };
  //       return r;
  //
  //     }
  //     .merge(t2.map((r, k) => fields.has(k) ? r.set('Parent', newParentId) : r))
  //     .merge(after));
  //
  //   const oldParentId = fields.reduce((R, r) => r.get('Parent'), null);
  //   // подчиненные элементы нужно переподчинить родителю переносимого поля
  //   const newTable2 = from < to
  //     ? newTable.map((r) => fields.has(r.get('Parent')) ? r.set('Parent', oldParentId) : r)
  //     : newTable;
  //
  //   dispatch({
  //     type: reportEditorActions.CHANGE_PROPERTY,
  //     payload: {
  //       path, //
  //       value: newTable2,
  //       model: storePathParam(getState()).name,
  //     },
  //   });
  // };

  const goToOldVersion = () => {
    const modelDef = definition[modelType][modelName];

    const getOldVersionLink = async () => {
      const url = `${modelType}/${modelDef.backendName}/getURL`;
      const r = await api.get$(url);
      if (!r.ok) {
        try {
          await r.text();
        } catch {
          serverRequestError(`${r.status} ${r.statusText}`);
        }
      }
      return r.json();
    };

    getOldVersionLink()
      .then((d) => {
        try {
          window.open(d.oldVersionRef, '_blank').focus();
        } catch {
          serverRequestError('В адресній строці необхідно дозволити відкривання нових вкладок');
        }
      })
      .catch((e) => {
        serverRequestError(e);
      });
  };

  const actions = {
    init,
    processServerReq,
    generate,
    changeField,
    activateTableRow,
    fillTable,
    getFile,
    DCAddField,
    DCSelectField,
    DCDeleteField,
    DCToggleField,
    cancelRequest,
    goToOldVersion,
    reportToFile,
  };

  return {
    data: data.current,
    err,
    actions,
    setLoading,
    loading,
  };
};

export default useEditor;
