/* eslint-disable react/no-array-index-key */
import React, { useCallback, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { Cattablerow } from '../../../newLister/tableComponent/table/row';
import { defaultColumns } from '../../../../constants/meta/common';

const NODES = ['Отрасль', 'ПримечаниеСпр'];
const RenderTree = ({
  node, level, columns, tabIndex, actions, isOpened, onContextMenu, onFocus, permissions, selected,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = useCallback(
    () => setIsOpen((o) => !o),
    [],
  );
  console.log(node);
  const row = useMemo(
    () => ({ ...node, id: node['Ссылка']?.id }),
    [node],
  );

  if (level === NODES.length) {
    return (
      <Cattablerow
        row={row}
        columns={columns}
        tabIndex={tabIndex}
        actions={actions}
        isOpened={isOpened}
        onContextMenu={onContextMenu}
        onFocus={onFocus}
        permissions={permissions}
        selected={selected}
      />
    );
  }

  return (
    <>
      <tr>
        <td colSpan={columns.length}>
          <Button variant="link" onClick={toggleOpen}>
            <FontAwesomeIcon icon={faCircleChevronDown} rotation={isOpen ? 180 : 0} style={{ transition: 'all ease-in 0.3s' }} />
          </Button>
          <span
            className="ms-4 text-start"
            style={{ paddingLeft: `${level}em` }}
          >
            {node[NODES[level]].repr || 'Без назви'}
          </span>
        </td>
      </tr>
      {isOpen && node.child && node.child.map((childNode, i) => (
        <RenderTree
          key={i}
          node={childNode}
          level={level + 1}
          columns={columns}
          tabIndex={tabIndex}
          actions={actions}
          isOpened={isOpened}
          onContextMenu={onContextMenu}
          onFocus={onFocus}
          permissions={permissions}
          selected={selected}
        />
      ))}
    </>
  );
};

RenderTree.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.oneOf([
      'date', 'moneys', 'boolVariants', 'bool',
    ]),
  })).isRequired,
  level: PropTypes.number,
  node: PropTypes.shape({
    id: PropTypes.string.isRequired,
    level: PropTypes.number,
    [defaultColumns.isDeleted.name]: PropTypes.bool.isRequired,
    [defaultColumns.isGroup.name]: PropTypes.bool,
    Ссылка: PropTypes.shape({ id: PropTypes.number.isRequired }),
    child: PropTypes.shape([{}]),
  }).isRequired,
  onFocus: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  tabIndex: PropTypes.number.isRequired,
  onContextMenu: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    canHierarchy: PropTypes.bool,
    foldersUsed: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    onToggle: PropTypes.func.isRequired,
    enEdit: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
  }).isRequired,
  isOpened: PropTypes.bool,
};

RenderTree.defaultProps = {
  selected: false,
  isOpened: false,
  level: 0,
};
export default RenderTree;
