import React, {
  memo, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { PrintableTable } from './styles';
import Row from './row';

const objToArray = (obj) => Object.keys(obj).map((key) => obj[key]);

const TableRenderer = ({
  addStyles, columns, headerRows, bodyRows, name, activeCell, editMode, onActivateCell,
  onResizeColumn, fixedLeft, fixedTop, onReportDetails, scroller, maxWidth,
}) => {
  const tableRef = useRef();
  // Признак того, что вся таблица болжна быть зафикисрована
  // при этом position: sticky применяется не на td а на table

  const arrColumns = columns;
  const arrRows = bodyRows;

  const tableFixed = (arrRows
  && arrRows.length ? arrRows : headerRows)[arrRows.length - 1].id <= fixedTop;

  // Признак того, что хотябы одна из строк болжна быть зафикисрована
  const anyRowFixed = (headerRows && headerRows.length ? headerRows : arrRows)[0].id <= fixedTop;

  const { top, deltaTop, tableLeft } = useMemo(
    () => {
    // if (tableRef.current) console.log(tableRef.current.getBoundingClientRect());
      if (tableRef.current && anyRowFixed) {
        const c = tableRef.current.getBoundingClientRect();
        const s = scroller.getBoundingClientRect();
        return {
          top: tableFixed ? c.top - s.top : 0,
          deltaTop: s.top,
          tableLeft: c.left,
        };
      }
      return {
        top: 0,
        deltaTop: 0,
        tableLeft: 0,
      };
    },
    [anyRowFixed, scroller, tableFixed],
  );

  const printableAddStyles = useMemo(
    () => {
      if (!tableFixed) return addStyles;
      const deltaWidth = tableRef.current
        ? maxWidth - (tableRef.current.getBoundingClientRect().width / 16) : 0;
      return `
      ${addStyles}
      position: sticky;
      z-index: 3;
      & td {
        box-shadow: 0 2px 2px -1px black;
      }
      &:after{
        content: '';
        width: ${deltaWidth}rem;
        top: 0;
        height: 100%;
        left: 100%;
        position: absolute;
        background: white;
      }
    `;
    },
    [addStyles, maxWidth, tableFixed],
  );

  return (
    <PrintableTable
      ref={tableRef}
      fixed={tableFixed}
      top={top}
      addStyles={printableAddStyles}
    >
      <thead>
        <tr>
          {arrColumns.map((c) => (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <td
              className={`col${c.id} empty-column`}
              key={c.id}
            >
              <div />
            </td>
          ))}
        </tr>
        {headerRows.map((row) => (
          <Row
            key={row.id}
            cells={objToArray(row.cells)}
            tableName={name}
            activeCell={activeCell.row === row.id ? activeCell.column : null}
            editMode={activeCell.row === row.id && editMode}
            onActivateCell={onActivateCell}
            onResizeColumn={onResizeColumn}
            fixedLeft={fixedLeft}
            fixedTop={(!tableFixed) && row.id <= fixedTop}
            onReportDetails={onReportDetails}
            tableTop={deltaTop}
            tableLeft={tableLeft}
          />
        ))}

      </thead>
      <tbody>
        {arrRows.map((row) => (
          <Row
            key={row.id}
            cells={objToArray(row.cells)}
            tableName={name}
            activeCell={activeCell.row === row.id ? activeCell.column : null}
            editMode={activeCell.row === row.id && editMode}
            onActivateCell={onActivateCell}
            onResizeColumn={onResizeColumn}
            fixedLeft={fixedLeft}
            fixedTop={(!tableFixed) && row.id <= fixedTop}
            onReportDetails={onReportDetails}
            tableTop={deltaTop}
            tableLeft={tableLeft}
          />
        ))}
      </tbody>
    </PrintableTable>
  );
};

TableRenderer.propTypes = {
  addStyles: PropTypes.string.isRequired,
  columns: PropTypes.instanceOf([]).isRequired,
  headerRows: PropTypes.instanceOf([]),
  bodyRows: PropTypes.instanceOf([]).isRequired,
  name: PropTypes.number.isRequired,
  activeCell: PropTypes.shape({
    row: PropTypes.number,
    column: PropTypes.number,
  }).isRequired,
  editMode: PropTypes.bool.isRequired,
  onActivateCell: PropTypes.func.isRequired,
  onResizeColumn: PropTypes.func.isRequired,
  fixedLeft: PropTypes.number.isRequired,
  fixedTop: PropTypes.number.isRequired,
  onReportDetails: PropTypes.func.isRequired,
  scroller: PropTypes.instanceOf(Element).isRequired,
  maxWidth: PropTypes.number.isRequired,
};

TableRenderer.defaultProps = {
  headerRows: [],
};

export default memo(TableRenderer);
