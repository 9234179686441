import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CustomModal = ({
  triggerButton, header, children, opened, onClose, size,
}) => {
  const MyButton = () => triggerButton;
  return (
    <Modal
      onHide={onClose}
      size={size}
      show={opened}
    >
      <Modal.Header closeButton>
        {header}
      </Modal.Header>
      <Modal.Body>
        <MyButton />
        {children}
      </Modal.Body>

    </Modal>
  );
};

CustomModal.propTypes = {
  triggerButton: PropTypes.func.isRequired,
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

CustomModal.defaultProps = {
  size: 'lg',
};
export default CustomModal;
