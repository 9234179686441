import React, { memo } from 'react';
import PropTypes from 'prop-types';
import RenderTree from './RenderTree';
import { defaultColumns } from '../../../../constants/meta/common'; // Импортируем RenderTree компонент

const MaincalcRow = ({
  row, columns, tabIndex, actions, isOpened, onContextMenu, onFocus, permissions, selected,
}) => (
  <RenderTree
    node={row}
    level={0}
    columns={columns}
    tabIndex={tabIndex}
    actions={actions}
    isOpened={isOpened}
    onContextMenu={onContextMenu}
    onFocus={onFocus}
    permissions={permissions}
    selected={selected}
  />
);
MaincalcRow.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.oneOf([
      'date', 'moneys', 'boolVariants', 'bool',
    ]),
  })).isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    level: PropTypes.number,
    [defaultColumns.isDeleted.name]: PropTypes.bool.isRequired,
    [defaultColumns.isGroup.name]: PropTypes.bool,
  }).isRequired,
  onFocus: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  tabIndex: PropTypes.number.isRequired,
  onContextMenu: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    canHierarchy: PropTypes.bool,
    foldersUsed: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    onToggle: PropTypes.func.isRequired,
    enEdit: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
  }).isRequired,
  isOpened: PropTypes.bool,
};

MaincalcRow.defaultProps = {
  selected: false,
  isOpened: false,
};

export default memo(MaincalcRow);
