import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const AccButton = styled(FontAwesomeIcon).attrs(({ opened }) => ({
  style: {
    bottom: opened ? '0' : '-10px',
    transform: opened ? 'rotate(0)' : 'rotate(180deg)',
  },
}))`
  color: var(--info);
  cursor: pointer;
  position: absolute;
  right: -10px;
  z-index: 1021;
  transition: all .3s ease-in;
  &:hover {
  color: var(--primary);
  height: 20px;
  width: 20px;
  }
`;

const EditorCollapse = ({ children }) => {
  const [key, setKey] = useState('header');
  const onClick = useCallback(
    // eslint-disable-next-line no-confusing-arrow
    () => setKey((oldKey) => oldKey ? '' : 'header'),
    [],
  );
  return (
    <Accordion activeKey={key}>
      <Container fluid className="position-relative">
        <Accordion.Collapse eventKey="header">
          {children}
        </Accordion.Collapse>
        <AccButton icon={faAngleDoubleUp} onClick={onClick} opened={key} />
      </Container>
    </Accordion>
  );
};

EditorCollapse.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

export default EditorCollapse;
