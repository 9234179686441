import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import md from '../../../../constants/meta/catalogs/users';

const Users = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;
  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Name.label}
            value={data[md.columns.Name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Name.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.FullName.label}
            value={data[md.columns.FullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />

        </Col>
        <Col>
          <EditorControls.CheckboxInput
            controlId={`AccessToInfoBaseGranted-${data.id}`}
            label={md.columns.AccessToInfoBaseGranted.label}
            value={data[md.columns.AccessToInfoBaseGranted.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.AccessToInfoBaseGranted.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.Note.label}
            value={data[md.columns.Note.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Note.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
};

Users.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Users;
