import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import focusNextElement from '../../../common/funcs';

/**
 *
 * @param tableData {[]}
 * @param onChange {function}
 * @param tableName {string}
 *
 * @returns {{
 *    activeRow: number,
 *    activeCol: string,
 *    tableContainerRef: {current},
 *    highlights: string[],
 *    showFooter: bool,
 *    pinHeader: bool,
 *    tableActions: {
 *          onAddRow(function(@param defaults {{}}): void),
 *          onCopyRow(function(@param rowIndex {number}): void),
 *          onDeleteRow(function(@param rowIndex {number}): void),
 *          onMoveUpRow(function(@param rowIndex {number}): void),
 *          onMoveDownRow(function(@param rowIndex {number}): void),
 *          onCellChange(function(@param rowIndex {number}, @param partData {{}}): void),
 *          onHighlightColumn(function(@param columns {''[], ''}): void),
 *          onUnhighlightColumn(function(@param columns {''[], ''}): void),
 *          onToggleHighlightColumn(function(@param columns {''[], ''}): void),
 *          onToggleFooter(function(): void),
 *          onTogglePin(function(): void),
 *    },
 * }}

*/
const useTablePart = ({
  tableData, onChange, tableName,
}) => {
  const [active, setActive] = useState({ row: null, col: null });
  const [highlights, setHighlights] = useState([]); // ПОдсвеченные колонки (массив имен)
  const [showFooter, setShowFooter] = useState(true);
  const [pinHeader, setPinHeader] = useState(true);
  const tableContainerRef = useRef();
  const rowsCount = tableData.length;

  const onAddRow = useCallback(
    async (defaults = {}) => {
      await onChange((oldData) => ({
        ...oldData,
        [tableName]: [...oldData[tableName], defaults],
      }));
      setActive({ row: rowsCount, col: null });
    },
    [onChange, rowsCount, tableName],
  );

  const onCopyRow = useCallback(
    async (rowIndex) => {
      await onChange(
        (oldData) => ({
          ...oldData,
          [tableName]: [...oldData[tableName], oldData[tableName][rowIndex]],
        }),
      );
      setActive({ row: rowsCount, col: null });
    },
    [onChange, rowsCount, tableName],
  );

  const onDeleteRow = useCallback(
    async (rowIndex) => {
      await onChange((oldData) => ({
        ...oldData,
        [tableName]: [
          ...oldData[tableName].slice(0, rowIndex),
          ...oldData[tableName].slice(rowIndex + 1),
        ],
      }));
      // eslint-disable-next-line no-confusing-arrow
      setActive((o) => o.row === rowsCount - 1 ? { row: rowsCount - 1, col: o.col } : o);
    },
    [onChange, rowsCount, tableName],
  );
  const onMoveUpRow = useCallback(
    async (rowIndex) => {
      if (rowIndex) {
        return onChange((oldData) => ({
          ...oldData,
          [tableName]: [
            ...oldData[tableName].slice(0, rowIndex - 1),
            oldData[tableName][rowIndex],
            oldData[tableName][rowIndex - 1],
            ...oldData[tableName].slice(rowIndex + 1),
          ],
        }));
      }
      return onChange((oldData) => ({
        ...oldData,
        [tableName]: [...oldData[tableName].slice(1), oldData[tableName][0]],
      }));
    },
    [onChange, tableName],
  );

  const onMoveDownRow = useCallback(
    async (rowIndex) => {
      if (rowIndex < rowsCount - 1) {
        return onChange((oldData) => ({
          ...oldData,
          [tableName]: [
            ...oldData[tableName].slice(0, rowIndex),
            oldData[tableName][rowIndex + 1],
            oldData[tableName][rowIndex],
            ...oldData[tableName].slice(rowIndex + 2),
          ],
        }));
      }
      return onChange((oldData) => ({
        ...oldData,
        [tableName]: [
          oldData[tableName][rowIndex],
          ...oldData[tableName].slice(0, rowIndex),
        ],
      }));
    },
    [rowsCount, onChange, tableName],
  );

  const onCellChange = useCallback(
    async (e, rowIndex, partData) => onChange((oldData) => ({
      ...oldData,
      [tableName]: [
        ...oldData[tableName].slice(0, rowIndex),
        { ...oldData[tableName][rowIndex], ...partData },
        ...oldData[tableName].slice(rowIndex + 1),
      ],
    })),
    [onChange, tableName],
  );

  const onHighlightColumn = useCallback(
    (columns) => {
      const c = Array.isArray(columns) ? columns : [columns];
      setHighlights((o) => [...new Set([...o, ...c])]);
    },
    [],
  );
  const onUnhighlightColumn = useCallback(
    (columns) => {
      const c = Array.isArray(columns) ? columns : [columns];
      setHighlights((o) => o.filter((cc) => !c.includes(cc)));
    },
    [],
  );
  const onToggleHighlightColumn = useCallback(
    (columns) => {
      const c = Array.isArray(columns) ? columns : [columns];
      setHighlights((o) => [
        ...o.filter((cc) => !c.includes(cc)),
        ...c.filter((cc) => !o.includes(cc)),
      ]);
    },
    [],
  );

  const onToggleFooter = useCallback(
    () => setShowFooter((o) => !o),
    [],
  );
  const onTogglePin = useCallback(
    () => setPinHeader((o) => !o),
    [],
  );

  const tableActions = useMemo(
    () => ({
      onAddRow,
      onCopyRow,
      onDeleteRow,
      onMoveUpRow,
      onMoveDownRow,
      onCellChange,
      onHighlightColumn,
      onUnhighlightColumn,
      onToggleHighlightColumn,
      onToggleFooter,
      onTogglePin,
    }),
    [onAddRow,
      onCellChange,
      onCopyRow,
      onDeleteRow,
      onHighlightColumn,
      onMoveDownRow,
      onMoveUpRow,
      onToggleFooter,
      onToggleHighlightColumn,
      onTogglePin,
      onUnhighlightColumn],
  );

  const onFocusIn = useCallback(
    (e) => {
      if (tableContainerRef.current && tableContainerRef.current.contains(e.target)) {
        const colNode = e.target.closest('[data-col]');
        const rowNode = e.target.closest('[data-row]');
        const focusInfo = {
          col: colNode ? colNode.dataset.col : null,
          row: rowNode ? rowNode.dataset.row : null,

        };
        if (focusInfo.col === null) {
          console.warn('В событии фокус элемента не удалось найти ячейку табличной части для определения текущей колонки. '
            + 'Вероятно Вы забыли установить свойство "data-col={colName}" в компоненте ячейки', e.target);
        }
        if (focusInfo.row === null) {
          console.warn('В событии фокус элемента не удалось найти строку табличной части для определения текущей строки. '
            + 'Вероятно Вы забыли установить свойство "data-row={rowIndex}" в компоненте строки', e.target);
        }
        setActive({
          row: focusInfo.row ? Number.parseInt(focusInfo.row, 10) : null,
          col: focusInfo.col,
        });
      }
    },
    [],
  );

  const currActiveRow = useRef(null);

  useEffect(
    () => {
      currActiveRow.current = active.row;
    },
    [active.row],
  );
  const onKeyDown = useCallback(
    (e) => {
      if (tableContainerRef.current.contains(e.target)) {
        if (e.key === 'Enter') {
          e.preventDefault();
          focusNextElement(tableContainerRef.current);
        }
        if (e.key === 'Insert') {
          e.preventDefault();
          onAddRow();
        }
        if (e.key === 'F8') {
          e.preventDefault();
          onDeleteRow(currActiveRow.current);
        }
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setActive((o) => ({ ...o, row: o.row ? o.row - 1 : rowsCount - 1 }));
        }
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          setActive((o) => ({ ...o, row: rowsCount - 1 ? o.row + 1 : 0 }));
        }
      }
    },
    [onAddRow, onDeleteRow, rowsCount],
  );

  useEffect(
    () => {
      const node = tableContainerRef.current;
      node.addEventListener('focusin', onFocusIn);
      node.addEventListener('keydown', onKeyDown);
      return () => {
        node.removeEventListener('focusin', onFocusIn);
        node.removeEventListener('keydown', onKeyDown);
      };
    },
  );

  return {
    activeRow: active.row,
    activeCol: active.col,
    showFooter,
    pinHeader,
    tableActions,
    tableContainerRef,
    highlights,
  };
};

export default useTablePart;
