import ListerFactory from '../../../newLister';
import { CalcTableListerFactory } from '../../../newLister/tableComponent';
import { modelType, modelName } from '../def';
import MaincalcRow from './maincalc_row';

const StdLister = ListerFactory({
  modelType,
  modelName,
  viewType: 'lister',
})(CalcTableListerFactory({ RowComponent: MaincalcRow }));

export default StdLister;
