import MainCalcLister from './mainCalc/lister/lister';
import MainCalcEditor from './mainCalc/editor/editor';

const calcComponents = {
  mainCalc: {
    editor: MainCalcEditor,
    lister: MainCalcLister,
  },
};

export {
  calcComponents,
};

export default {
  calcComponents,
};
