import React from 'react';
import PropTypes from 'prop-types';
import { faCity, faMapMarker, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { referencePropType } from '../../newEditor/propTypes';

const FI_PROP_NAME = 'ФинансовыйОрган';
const BUDGET_PROP_NAME = 'Бюджет';
const AUTHOR_PROP_NAME = 'Автор';

const defprops = [
  { prop: BUDGET_PROP_NAME, icon: faMapMarker },
  { prop: FI_PROP_NAME, icon: faCity },
  { prop: AUTHOR_PROP_NAME, icon: faUser },
];

const BudgetFOUserEditorShower = ({
  data,
}) => (
  <div className="d-flex justify-content-between">
    {defprops.map(({ prop, icon }) => (
      <span key={prop} className="text-muted">
        <FontAwesomeIcon icon={icon} className="me-2" />
        {data[prop] && data[prop].repr}
      </span>
    ))}
  </div>
);

BudgetFOUserEditorShower.propTypes = {
  data: PropTypes.shape({
    [FI_PROP_NAME]: referencePropType,
    [BUDGET_PROP_NAME]: referencePropType,
    [AUTHOR_PROP_NAME]: referencePropType,
  }).isRequired,
};

export default BudgetFOUserEditorShower;
