import React from 'react';
import PropTypes from 'prop-types';
import {
  Alert, Card,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Loader from './loader';

const EditorWrapper = ({
  onErrorDismis, err, loading, children,
}) => (
  <Card>
    {loading && (
      <Loader text="Зачекайте, будь ласка" />
    )}
    {err && (
      <Card.Footer>
        <Alert
          variant="danger"
          // className="position-absolute fixed-bottom mb-0 rounded-0"
          dismissible
          onClose={onErrorDismis}
        >
          <FontAwesomeIcon icon={faExclamationTriangle} className="me-3" pull="left" size="4x" />
          <Alert.Heading>
            Увага!
            <br />
            {err}
          </Alert.Heading>
        </Alert>
      </Card.Footer>
    )}
    {children}
  </Card>

);

EditorWrapper.propTypes = {
  onErrorDismis: PropTypes.func,
  err: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),

};

EditorWrapper.defaultProps = {
  onErrorDismis: null,
  err: null,
  loading: false,
  children: null,
};

export default EditorWrapper;
