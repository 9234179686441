import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/catalogs/post';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tableRowCell';

const tablename = 'solaryesClose';

const tableMD = md.tables[tablename];

const TPRow = ({
  row, active, rowIndex, activeCol, readOnly, highlights, onChange,
}) => {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol} noGutters>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col
            className="d-flex"
          >
            <Col>
              <TableRowCell column="calc" highlighted={highlights.includes('calc')}>
                <ItemPicker
                  value={row[tableMD.columns.calc.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.calc.name]: v })}
                  modelType="calc"
                  modelName="mainCalc"
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
};

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.calc.name]: referencePropType,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
