import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SidePanel = styled.div`
   display: flex;
   background: linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #4281C9;
   width: 216px;
`;

export const StyledUl = styled.ul`  
  padding: 0; 
  margin: 0;
  width: ${(props) => (props.isVisible ? '216px' : '50px')};
  flex-shrink: 0;
`;

export const StyledList = styled.li`
  display: flex;
  padding: 0px;
  list-style-type: none;
  min-height: 40px;  
`;

export const StyledLink = styled(({ isVisible, backgroundColor, ...rest }) => (<Link {...rest} />))`
  align-self: left;
 ${(props) => (props.backgroundColor ? `background: ${props.backgroundColor}` : '')};
  display: flex;
  color: white;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 15.5px;
  height: 100%;
  width: 100%;
  padding: 10px 12px;
  transition: all .3s;
  text-decoration: none;
  // overflow: ${(props) => props.isVisible || 'hidden'}
  >span{
    display: ${(props) => props.isVisible || 'none'}
  }
  *{stroke: white;}
  &{li > a}:hover {
    background: #4281C9;
    color: #FFFFFF;
  }
  :focus {outline:none;}
`;

export default {
  StyledLink,
  StyledList,
  SidePanel,
  StyledUl,
};
