import React, {
  useEffect, useMemo, memo,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useReportEditor from './hooks/useReportEditor';

export const Message = styled.div`
  display: flex;
  align-items: center;
  background: linear-gradient(180deg,#E5EDF7 0%,#fcfdff 100%); 
  border: solid #DDE2E9; 
  border-width: 1px 1px 0 1px;
  border-radius: 4px 4px 0 0;
  font-weight: 700;
  margin-bottom: -1px;
  padding: 30px;
  // width: 100%;
  font-size: 1.5em;    
`;
export const ErrorMessage = styled(Message)`
  color: red;
`;

/**
 * @param model {String}
 * @param modelType {String}
 * @param extra.menu {React.Component}
 * @param extra.tables {React.Component}
 * @param extra.footer {React.Component}
 * @returns {function(<object>): *}
 * @constructor
 */
const EditorHOC = ({
  name, type, extra,
}) => (WrappedComponent) => {
  const BasicEditor = ({
    isProcessing, hasError, errorMsg, mapInitialOptions, ...restProps
  }) => {
    const { actions } = useReportEditor({ modelName: name });
    useEffect(
      () => actions.init(type, name, mapInitialOptions, extra.autoExecute),
      [mapInitialOptions, actions],
    );

    const MenuWrapper = extra && extra.menu;
    const TablesWrapper = extra && extra.tables;
    const FooterWrapper = extra && extra.footer;

    const compProps = useMemo(
      () => ({
        isProcessing,
        hasError,
        errorMsg,
        ...restProps,
      }),
      [errorMsg, hasError, isProcessing, restProps],
    );
    return (
      <>
        {MenuWrapper && (
          <MenuWrapper
            disabled={isProcessing || hasError}
          />
        )}
        {hasError && (
          <ErrorMessage>
            {errorMsg}
          </ErrorMessage>
        )}
        <WrappedComponent {...compProps} />
        {TablesWrapper && (
          <TablesWrapper />
        )}
        {FooterWrapper && React.cloneElement(FooterWrapper, compProps)}
      </>
    );
  };

  BasicEditor.displayName = `Editor(${WrappedComponent.displayName || 'UnknownComponent'})`;

  BasicEditor.propTypes = {
    isProcessing: PropTypes.bool,
    hasError: PropTypes.bool,
    errorMsg: PropTypes.string,
    children: PropTypes.node,
    mapInitialOptions: PropTypes.shape(),
    mapStore: PropTypes.func,
  };

  BasicEditor.defaultProps = {
    isProcessing: false,
    hasError: false,
    errorMsg: null,
    mapInitialOptions: null,
    mapStore: null,
    children: null,
  };
  return memo(BasicEditor);
};

export default EditorHOC;
