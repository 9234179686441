import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import TreeviewListItem from './item';

const Treeview = ({
  tableData, expanded, currentItem, setCurrentItem, visibleColumns, onChange,
}) => {
  const [openedItems, setOpenedItems] = useState(expanded);

  const onRowChange = useCallback(
    (row, rowIndex) => onChange(tableData.map((r, i) => (i === rowIndex ? row : r))),
    [onChange, tableData],
  );

  return (
    <div className="ps-3">
      {tableData.map((treeviewItem, rowIndex) => (
        <div
          key={treeviewItem.Показатель.id}
          className="text-center py-1"
        >
          <TreeviewListItem
            item={treeviewItem}
            openedItems={openedItems}
            setOpenedItems={setOpenedItems}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            visibleColumns={visibleColumns}
            level={0}
            onChange={onRowChange}
            rowIndex={rowIndex}
          />
        </div>
      ))}
    </div>
  );
};

Treeview.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      Показатель: PropTypes.shape({
        id: PropTypes.string.isRequired,
        repr: PropTypes.string.isRequired,
      }).isRequired,
      _child: PropTypes.arrayOf(PropTypes.shape([])),
    }),
  ).isRequired,
  expanded: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  currentItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setCurrentItem: PropTypes.func,
  visibleColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
};

Treeview.defaultProps = {
  expanded: [],
  currentItem: null,
  setCurrentItem: null,
};

export default Treeview;
