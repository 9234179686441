import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const TPHeader = ({
  visibleColumns,
}) => {
  if (visibleColumns.length === 0) return null;
  return (
    <TableHeaderRow noGutters>
      <Col
        xl={12}
        sm={12}
        className="px-3  d-flex flex-column justify-content-end"
      >
        <Row>
          <Col md={4}>
            <TableHeaderCell
              className="text-center text-xl-left"
            >
              {visibleColumns[0].repr}
            </TableHeaderCell>
          </Col>
          {visibleColumns.filter((c, i) => !!i).map(({ id, repr }) => (
            <Col key={id}>
              <TableHeaderCell
                className="text-center text-xl-left"
              >
                {repr}
              </TableHeaderCell>
            </Col>
          ))}
        </Row>
      </Col>
    </TableHeaderRow>
  );
};

TPHeader.propTypes = {
  visibleColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      repr: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default memo(TPHeader);
