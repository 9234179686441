import React, {
  memo, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, Collapse, Row, Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';
import inTotalColumns from 'lodash';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { numberFormat } from '../../../../components/bootstrap_components/controls/numberInput';
// eslint-disable-next-line import/no-cycle
import { totalColumns } from '../editor/tabs/general';

const TreeviewListItem = ({
  item,
  openedItems,
  setOpenedItems,
  currentItem,
  setCurrentItem,
  visibleColumns,
  level = 0,
  onChange,
  rowIndex,
}) => {
  const [open, setOpen] = useState(openedItems.includes(item.id));
  const hasChildren = (item.child && item.child.length > 0);

  const handleToggle = () => {
    setOpen(!open);
    if (setCurrentItem) setCurrentItem(item.id);
  };

  const onRowChange = useCallback(
    (rowData) => {
      const newRow = { ...item, ...rowData };
      newRow['Итого'] = inTotalColumns.reduce((R, c) => R + newRow[c], 0);
      onChange(newRow, rowIndex);
    },
    [item, onChange, rowIndex],
  );

  const onChildrenChange = useCallback(
    (row, rowIndex2) => {
      const newChildren = item.child.map((r, i) => (i === rowIndex2 ? row : r));
      onRowChange(totalColumns.reduce((R, c) => ({
        ...R,
        [c]: newChildren.reduce((R2, r2) => R2 + r2[c], 0),
      }), { child: newChildren }));
    },
    [item.child, onRowChange],
  );

  return (
    <>
      <Row className="border-bottom py-1 d-flex align-items-center" style={{ maxWidth: '100%', overflowX: 'hidden' }}>
        <Col md={4} className="d-flex position-relative">
          {hasChildren && (
            <Button
              variant="link"
              className="p-0 position-absolute"
              style={{ left: 0 }}
              onClick={handleToggle}
              aria-expanded={open}
            >
              <FontAwesomeIcon icon={faCircleChevronDown} rotation={open ? 180 : 0} style={{ transition: 'all ease-in 0.3s' }} />
            </Button>
          )}
          <span
            className="ms-4 text-start"
            style={{ paddingLeft: `${level}em` }}
          >
            {item.Показатель?.repr}
          </span>
        </Col>

        {visibleColumns.filter((column) => column.id !== 'Показатель').map((column) => {
          const isEditable = !hasChildren && column.id !== 'Итого';

          return (
            <Col key={column.id} className="text-end">
              {isEditable ? (
                <EditorControls.NumberInput
                  value={numberFormat(item[column.id], 1)}
                  onChange={(e, v) => onRowChange({ [column.id]: v })}
                />
              ) : (
                <div className="pe-2">
                  {numberFormat(item[column.id], 1)}
                </div>
              )}
            </Col>
          );
        })}
      </Row>
      {hasChildren && (
        <Collapse in={open}>
          <div>
            {item.child.map((child, rowIndex2) => (
              <div key={child.Показатель.id}>
                <TreeviewListItem
                  item={child}
                  openedItems={openedItems}
                  setOpenedItems={setOpenedItems}
                  currentItem={currentItem}
                  setCurrentItem={setCurrentItem}
                  visibleColumns={visibleColumns}
                  level={level + 1}
                  onChange={onChildrenChange}
                  rowIndex={rowIndex2}
                />
              </div>
            ))}
          </div>
        </Collapse>
      )}
    </>
  );
};

TreeviewListItem.propTypes = {
  item: PropTypes.shape({
    Показатель: PropTypes.shape({
      id: PropTypes.string.isRequired,
      repr: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    child: PropTypes.arrayOf(
      PropTypes.shape({
        Показатель: PropTypes.shape({
          id: PropTypes.string.isRequired,
          repr: PropTypes.string.isRequired,
        }),
      }),
    ),
  }).isRequired,
  openedItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  setOpenedItems: PropTypes.func.isRequired,
  currentItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setCurrentItem: PropTypes.func,
  visibleColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      repr: PropTypes.string.isRequired,
    }),
  ).isRequired,
  level: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  rowIndex: PropTypes.number.isRequired,
};

TreeviewListItem.defaultProps = {
  openedItems: [],
  currentItem: null,
  setCurrentItem: null,
  level: 0,
};

export default memo(TreeviewListItem);
