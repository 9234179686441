import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DpEditor from './editor';
import { modelType, modelName } from './def';
import useEditor from '../../newDpEditor/hooks/useReportEditor';
import { ErrorMessage } from '../../newReportEditor/basic';
import { AppContext } from '../../../providers/authProvider';

const Dp = ({
  extra,
}) => {
  const appContext = useContext(AppContext);
  const { sessionOptions } = appContext;
  const { actions, data } = useEditor({ modelName, modelType });

  const { isProcessing, hasError, errorMsg } = data;

  const MenuWrapper = extra && extra.menu;
  const TablesWrapper = extra && extra.tables;
  const FooterWrapper = extra && extra.footer;

  return (
    <>
      {MenuWrapper && (
        <MenuWrapper />
      )}
      {hasError && (
        <ErrorMessage>
          {errorMsg}
        </ErrorMessage>
      )}
      <Card.Header>
        <DpEditor
          isProcessing={isProcessing}
          portalOpened={false}
          sessionOptions={sessionOptions}
          data={data}
          actions={actions}
        />
      </Card.Header>
      {TablesWrapper && (
        <TablesWrapper />
      )}
      {FooterWrapper && React.cloneElement(FooterWrapper, data)}
    </>
  );
};

Dp.propTypes = {
  mapInitialOptions: PropTypes.shape({}),
  extra: PropTypes.shape([]),
};

Dp.defaultProps = {
  mapInitialOptions: null,
  extra: null,
};

export default Dp;
