import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
  NumberInput,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/documents/personCard';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tableRowCell';

const tablename = 'main';

const tableMD = md.tables[tablename];

const TPRow = ({
  row, active, rowIndex, activeCol,
  onChange, readOnly, highlights, tpMainBaseSumReadOnly, onSR,
}) => {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  const onBASEDOCChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.baseDoc.name]: v });
      await onSR('CHANGE_BASEDOC_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  const onBASESUMChange = useCallback(
    async (e, v) => {
      await onRowChange(e, { [tableMD.columns.baseSum.name]: v });
      await onSR('CHANGE_BASESUM_IN_TABLE', { rowId: rowIndex });
    },
    [onRowChange, onSR, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol}>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col
            className="d-flex"
          >
            <Col xl={6}>
              <TableRowCell column="baseDoc" highlighted={highlights.includes('baseDoc')}>
                <ItemPicker
                  value={row[tableMD.columns.baseDoc.name]}
                  onChange={onBASEDOCChange}
                  modelType="cat"
                  modelName="baseDoc"
                  noHierarchy
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col xl={4}>
              <TableRowCell column="minSalary" highlighted={highlights.includes('minSalary')}>
                <NumberInput
                  value={row[tableMD.columns.minSalary.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.minSalary.name]: v })}
                  precision={2}
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            <Col xl={4}>
              <TableRowCell column="baseSum" highlighted={highlights.includes('baseSum')}>
                <NumberInput
                  value={row[tableMD.columns.baseSum.name]}
                  onChange={onBASESUMChange}
                  precision={2}
                  readOnly={tpMainBaseSumReadOnly}
                />
              </TableRowCell>
            </Col>
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
};

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.baseDoc.name]: referencePropType,
    [tableMD.columns.minSalary.name]: PropTypes.number,
    [tableMD.columns.baseSum.name]: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  tpMainBaseSumReadOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSR: PropTypes.func.isRequired,
  //
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
  tpMainBaseSumReadOnly: false,
};

export default memo(TPRow);
