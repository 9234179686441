import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import md from '../../../../../../constants/meta/documents/calcNightAndHoliday';
import { TableHeaderRow } from '../../../../../newEditor/tablePart/styles';
import TableHeaderCell from '../../../../../newEditor/tablePart/tableHeaderCell';

const meta = md.tables.general;

const TPHeader = ({
  activeCol,
  highlights,
  onToggleHighlght, numDayVisible, h50Visible,
}) => (
  <TableHeaderRow noGutters>
    <Col
      xl={12}
      sm={12}
      className="px-3  d-flex flex-column justify-content-end"
    >
      <Row>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'category'}
            highlighted={highlights.includes('category')}
            onToggleHighlght={() => onToggleHighlght('category')}
          >
            {meta.columns.category.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'numPostUse'}
            highlighted={highlights.includes('numPostUse')}
            onToggleHighlght={() => onToggleHighlght('numPostUse')}
          >
            {meta.columns.numPostUse.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'numPostBase'}
            highlighted={highlights.includes('numPostBase')}
            onToggleHighlght={() => onToggleHighlght('numPostBase')}
          >
            {meta.columns.numPostBase.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'numStateUse'}
            highlighted={highlights.includes('numStateUse')}
            onToggleHighlght={() => onToggleHighlght('numStateUse')}
          >
            {meta.columns.numStateUse.label}
          </TableHeaderCell>
        </Col>
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'numStateBase'}
            highlighted={highlights.includes('numStateBase')}
            onToggleHighlght={() => onToggleHighlght('numStateBase')}
          >
            {meta.columns.numStateBase.label}
          </TableHeaderCell>
        </Col>
        {(numDayVisible) && (
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'numDay'}
              highlighted={highlights.includes('numDay')}
              onToggleHighlght={() => onToggleHighlght('numDay')}
            >
              {meta.columns.numDay.label}
            </TableHeaderCell>
          </Col>
        )}
        <Col>
          <TableHeaderCell
            className="text-center text-xl-left"
            active={activeCol === 'hTotal'}
            highlighted={highlights.includes('hTotal')}
            onToggleHighlght={() => onToggleHighlght('hTotal')}
          >
            {meta.columns.hTotal.label}
          </TableHeaderCell>
        </Col>
        {(h50Visible) && (
          <Col>
            <TableHeaderCell
              className="text-center text-xl-left"
              active={activeCol === 'h50'}
              highlighted={highlights.includes('h50')}
              onToggleHighlght={() => onToggleHighlght('h50')}
            >
              {meta.columns.h50.label}
            </TableHeaderCell>
          </Col>
        )}
      </Row>
    </Col>
  </TableHeaderRow>
);

TPHeader.propTypes = {
  activeCol: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleHighlght: PropTypes.func.isRequired,
  numDayVisible: PropTypes.bool,
  h50Visible: PropTypes.bool,
  ///
};

TPHeader.defaultProps = {
  activeCol: '',
  numDayVisible: false,
  h50Visible: false,
  /// /
};

export default memo(TPHeader);
