import React from 'react';
import PropTypes from 'prop-types';
import meta from '../../../constants/meta';

import {
  StyledLabel,
  MyContainerFormWithNColumns, FrameDiv,
} from '../../../components/Form/styledForm';

import { EditorControls } from '../../../components/bootstrap_components/editorControls';

import Loading from '../../../components/styledLoading';

const md = meta.dp.setWorkDate;

const DpEditor = ({
  isProcessing, actions, data,
}) => (
  <FrameDiv>
    {isProcessing
              && <Loading />}
    <MyContainerFormWithNColumns columns={2} style={{ marginBottom: '0px' }}>
      <div>
        <StyledLabel>{md.columns.workDate.label}</StyledLabel>
        <EditorControls.DateInput
          value={data?.headerForm && data.headerForm[md.columns.workDate.name]}
          onChange={(e, v) => actions.changeField([md.columns.workDate.name], v)}
        />
      </div>
    </MyContainerFormWithNColumns>
  </FrameDiv>
);

DpEditor.propTypes = {
  isProcessing: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    headerForm: PropTypes.shape({}),
  }).isRequired,
  actions: PropTypes.shape({
    changeField: PropTypes.func.isRequired,
    processServerReq: PropTypes.func.isRequired,
  }).isRequired,
  sessionOptions: PropTypes.shape({
    is_admin: PropTypes.bool,
  }).isRequired,
};

DpEditor.defaultProps = {
  isProcessing: false,
};

export default DpEditor;
