import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col, Container, Row, Tab, Tabs,
} from 'react-bootstrap';

import { csu as md } from '../../../../constants/meta/catalogs/CSU';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

import enums from '../../../../constants/meta/enums';
import { ExtRepTP } from './tabs';

// eslint-disable-next-line max-len
const sValues = Object.values(enums.PaymentGroups).map((st) => ({ value: st.name, display_name: st.label }));

const CSU = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;

  const mainPane = (
    <Container fluid className="border border-top-0">
      <Row className="py-3">
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.grPayment.label}
            value={data[md.columns.grPayment.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.grPayment.name]: value,
            })}
            readOnly={readOnly}
            values={sValues}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.sorting.label}
            value={data[md.columns.sorting.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.sorting.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          <EditorControls.TextInput
            label={md.columns.FullName2.label}
            value={data[md.columns.FullName2.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.FullName2.name]: value,
            })}
            readOnly={readOnly}
            rows={2}
          />
        </Col>
      </Row>
      <h4 className="mt-2">Зв`язок з КВК, станом на вказану дату</h4>
      <Row className="py-3">
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Classifier.label}
            value={data[md.columns.Classifier.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Classifier.name]: value,
            })}
            modelType="cat"
            modelName="kvkClassifier"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.DateInput
            label={md.columns.DateCl.label}
            value={data[md.columns.DateCl.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.DateCl.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          <EditorControls.StringInput
            label={md.columns.NetworkCode.label}
            value={data[md.columns.NetworkCode.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.NetworkCode.name]: value,
            })}
            maxLength={6}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.csuLvl.label}
            value={data[md.columns.csuLvl.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.csuLvl.name]: value,
            })}
            modelType="cat"
            modelName="csuLvl"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          <EditorControls.StringInput
            label={md.columns.EDRPOU.label}
            value={data[md.columns.EDRPOU.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.EDRPOU.name]: value,
            })}
            maxLength={8}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Bank.label}
            value={data[md.columns.Bank.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Bank.name]: value,
            })}
            modelType="cat"
            modelName="banks"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row className="py-3">
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.Budget.label}
            value={data[md.columns.Budget.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.Budget.name]: value,
            })}
            modelType="cat"
            modelName="budget"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="csu"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );

  const secondPane = (
    <Container fluid className="border border-top-0">
      <Row className="py-3">
        <Col>
          <Card>
            <Card.Header>
              <Card.Title />
            </Card.Header>
            <Card.Body>
              <ExtRepTP data={data} actions={actions} readOnly={readOnly} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  const thirdPane = (
    <Container fluid className="border border-top-0">
      <Row className="py-3">
        <Col>
          <Card>
            <Card.Header>
              <Card.Title />
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <EditorControls.CheckboxInput
                    controlId={`flGrant-${data.id}`}
                    label={md.columns.flGrant.label}
                    value={data[md.columns.flGrant.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.flGrant.name]: value,
                    })}
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <EditorControls.ItemPicker
                    label={md.columns.mainCsu.label}
                    value={data[md.columns.mainCsu.name]}
                    onChange={(e, value) => actions.onChange({
                      [md.columns.mainCsu.name]: value,
                    })}
                    modelType="cat"
                    modelName="csu"
                    readOnly={readOnly}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  return (
    <Container fluid>
      <Tabs defaultActiveKey="extRep">
        <Tab title="Основне" eventKey="extRep">
          {mainPane}
        </Tab>
        <Tab title="Зовнішні звіти" eventKey="access">
          {secondPane}
        </Tab>
        <Tab title="Тарифікація" eventKey="other">
          {thirdPane}
        </Tab>
      </Tabs>
    </Container>
  );
};

CSU.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default CSU;
