import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BigDivLoadStyled } from '../../containers/field/selectorField/item/styles';

const Container = styled.div`
  margin-top: 10px;
  display: flex;
  background: #e9f0f85e;
  border-radius: 4px;
  border: 1px solid #cbcbe4;
  align-items: baseline
`;
const Text = styled.span`
  margin-left: 10px;
  margin-top: 25px;
  color: #4281C9;
  font-weight: 700;
  font-size: 15px;
`;
const Loading = ({ children }) => (
  <Container>
    <BigDivLoadStyled />
    <Text>Зачекайте, будь ласка...</Text>
    {children}
  </Container>
);

Loading.propTypes = {
  children: PropTypes.node,
};

Loading.defaultProps = {
  children: null,
};

export default Loading;
