import SetWorkDateEditor from './setWorkDate';

const dpComponents = {
  setWorkDate: { editor: SetWorkDateEditor, isRoute: true },
};

export {
  dpComponents,
  SetWorkDateEditor,
};

export default dpComponents;
