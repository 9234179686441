import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { banks as md } from '../../../../constants/meta/catalogs/banks';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const Banks = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={150}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.UDK.label}
            value={data[md.columns.UDK.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.UDK.name]: value,
            })}
            maxLength={10}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.UDKName.label}
            value={data[md.columns.UDKName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.UDKName.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.VDK.label}
            value={data[md.columns.VDK.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.VDK.name]: value,
            })}
            maxLength={10}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.StringInput
            label={md.columns.VDKName.label}
            value={data[md.columns.VDKName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.VDKName.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.terra.label}
            value={data[md.columns.terra.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.terra.name]: value,
            })}
            maxLength={10}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.nameRasp.label}
            value={data[md.columns.nameRasp.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.nameRasp.name]: value,
            })}
            maxLength={600}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.hierarchyLevel.label}
            value={data[md.columns.hierarchyLevel.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.hierarchyLevel.name]: value,
            })}
            maxLength={1}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
};

Banks.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Banks;
