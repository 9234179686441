import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import useReportEditor from '../../newReportEditor/hooks/useReportEditor';
import md from '../../../constants/meta/reports/repTypeCharges';
import { modelType, modelName } from './def';
import { AppContext } from '../../../providers/authProvider';
import { ErrorMessage } from '../../newReportEditor/basic';
import ReportEditor from './repTypeCharges';

const Report = ({ autoExecute, mapInitialOptions, extra }) => {
  const appContext = useContext(AppContext);
  const { sessionOptions } = appContext;

  const { actions, data } = useReportEditor({ modelName });

  const { isProcessing, hasError, errorMsg } = data;

  useEffect(
    () => actions.init(modelType, modelName, mapInitialOptions, autoExecute),
    [actions, autoExecute, mapInitialOptions],
  );

  const MenuWrapper = extra && extra.menu;
  const TablesWrapper = extra && extra.tables;
  const FooterWrapper = extra && extra.footer;

  return (
    <>
      {MenuWrapper && (
        <MenuWrapper />
      )}
      {hasError && (
        <ErrorMessage>
          {errorMsg}
        </ErrorMessage>
      )}
      <ReportEditor
        isProcessing={isProcessing}
        portalOpened={false}
        sessionOptions={sessionOptions}
        data={data}
        actions={actions}
      />

      {TablesWrapper && (
        <TablesWrapper />
      )}
      {FooterWrapper && React.cloneElement(FooterWrapper, data)}
    </>
  );
};

Report.propTypes = {
  autoExecute: PropTypes.bool,
  mapInitialOptions: PropTypes.shape({}),
  extra: PropTypes.shape([]),
};

Report.defaultProps = {
  autoExecute: false,
  mapInitialOptions: null,
  extra: null,
};

Report.displayName = `Report${md.name}Editor`;

export default Report;
