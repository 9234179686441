import React, {
  memo, useCallback, useContext, useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  ButtonGroup, Button, Tabs, Tab,
} from 'react-bootstrap';
import WinManagerContext from '../../providers/winManagerProvider';

const ManagerLink = () => {
  const {
    dellComponentFromWindowsManager,
    linkComponents,
    currentURL,
    nextWindow,
    prevWindow,
  } = useContext(WinManagerContext);
  const navigate = useNavigate();

  const onCloseIconClick = useCallback(
    (e, value) => {
      e.preventDefault();
      e.stopPropagation();
      dellComponentFromWindowsManager(value);
    },
    [dellComponentFromWindowsManager],
  );

  const knowUrls = useMemo(
    () => linkComponents.map((l) => l.url),
    [linkComponents],
  );

  const tabValue = useMemo(
    () => (knowUrls.includes(currentURL) ? currentURL : false),
    [currentURL, knowUrls],
  );

  return (
    <div
      style={{
        display: 'flex', borderTop: '1px solid #afbbcc', width: '100%',
      }}
    >
      <ButtonGroup size="sm">
        <Button
          variant="light"
          onClick={() => prevWindow(navigate)}
        >
          <FontAwesomeIcon icon={faCaretLeft} />
        </Button>
        <Button
          variant="light"
          onClick={() => nextWindow(navigate)}
        >
          <FontAwesomeIcon icon={faCaretRight} />
        </Button>
      </ButtonGroup>

      <Tabs
        activeKey={tabValue}
        onSelect={(v) => navigate(v, { replace: true })}
        className="mb-3"
      >
        {
          linkComponents.map(({ title, url }) => (
            <Tab
              key={`link-key-${url}`}
              eventKey={url}
              title={(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ paddingBottom: '15px', display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '8px' }}>{title}</span>
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={(e) => onCloseIconClick(e, url)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </div>
              )}
            />
          ))
        }
      </Tabs>
    </div>
  );
};

export default memo(ManagerLink);
