import { useMemo } from 'react';
import meta from '../../../constants/meta';

export const useMD = (modelType, modelName) => {
  const md = useMemo(
    () => {
      if (!Object.keys(meta).includes(modelType)) {
        throw new Error(`modelType arg must be in ${Object.keys(meta).join(',')}, but not ${modelType}`);
      }
      if (!Object.keys(meta[modelType]).includes(modelName)) {
        throw new Error(`modelName arg must be in ${Object.keys(meta[modelType]).join(',')}, but not ${modelName}`);
      }
      return meta[modelType][modelName];
    },
    [modelName, modelType],
  );

  return md;
};

export default useMD;
