import React, {
  memo, useCallback,
} from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  ItemPicker,
} from '../../../../../../components/bootstrap_components/controls';
import md from '../../../../../../constants/meta/catalogs/post';
import TableRow from '../../../../../newEditor/tablePart/tablerow';
import { referencePropType } from '../../../../../newEditor/propTypes';
import TableRowCell from '../../../../../newEditor/tablePart/tableRowCell';
// import PaymentGroups from "../../../../../../constants/meta/enums/paymentGroups";

const tablename = 'usuals';

const tableMD = md.tables[tablename];

const TPRow = ({
  row, active, rowIndex, activeCol, readOnly, highlights, onChange,
}) => {
  const onRowChange = useCallback(
    (e, partData) => onChange(e, rowIndex, partData),
    [onChange, rowIndex],
  );

  return (
    <TableRow rowIndex={rowIndex} active={active} activeCol={activeCol} noGutters>
      <Col
        xl={12}
        sm={12}
        className="d-flex"
      >
        <Row className="w-100" noGutters>
          <Col
            className="d-flex"
          >
            <Col>
              <TableRowCell column="calcType" highlighted={highlights.includes('calcType')}>
                <ItemPicker
                  value={row[tableMD.columns.calcType.name]}
                  onChange={(e, v) => onRowChange(e, { [tableMD.columns.calcType.name]: v })}
                  modelType="calc"
                  modelName="mainCalc"
                  readOnly={readOnly}
                />
              </TableRowCell>
            </Col>
            {/* <Col> */}
            {/*  <TableRowCell column="sizeR" highlighted={highlights.includes('sizeR')}> */}
            {/*    <NumberInput */}
            {/*        value={row[tableMD.columns.sizeR.name]} */}
            {/*        onChange={(e, v) => onRowChange(e, { [tableMD.columns.sizeR.name]: v })} */}
            {/*        precision={4} */}
            {/*        readOnly={readOnly} */}
            {/*    /> */}
            {/*  </TableRowCell> */}
            {/* </Col> */}
          </Col>
        </Row>
      </Col>
    </TableRow>
  );
};

TPRow.propTypes = {
  row: PropTypes.shape({
    [tableMD.columns.calcType.name]: referencePropType,
    [tableMD.columns.sizeR.name]: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  activeCol: PropTypes.string,
  readOnly: PropTypes.bool,
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  //
};

TPRow.defaultProps = {
  active: false,
  activeCol: '',
  readOnly: false,
};

export default memo(TPRow);
