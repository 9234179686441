import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Row, Tab, Tabs,
} from 'react-bootstrap';
import { individuals as md } from '../../../../constants/meta/catalogs/individuals';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import {
  RaspTP,
  Employment308TP,
  EmploymentAllTP,
  EmploymentMainTP,
} from './tabs';
import { selectionMode } from '../../../../constants/meta/common';
import { AppContext } from '../../../../providers/authProvider';
import { CommandPanelColor } from '../../../../components/Form/styledForm';

const Limit = {
  first: { label: 'Чоловіча', name: 'Мужской' },
  second: { label: 'Жіноча', name: 'Женский' },
};

const sValues = Object.values(Limit).map((st) => ({ value: st.name, display_name: st.label }));

const Individuals = ({ data, actions }) => {
  // const readOnly = !permissions.canChange;
  const readOnly = data[md.columns.canEdit.name];

  const appContext = useContext(AppContext);
  const { sessionOptions } = appContext;
  // const INNreadOnly = !sessionOptions.is_admin && data[md.columns.canEdit.name];

  const CountLabel = !readOnly && (
    <Row>
      <Row>
        <Col>
          <span>Розрахунок дати початку роботи (для стажу)</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.countDate.label}
            value={data[md.columns.countDate.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.countDate.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.countYear.label}
            value={data[md.columns.countYear.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.countYear.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.countMonth.label}
            value={data[md.columns.countMonth.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.countMonth.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.NumberInput
            label={md.columns.countDay.label}
            value={data[md.columns.countDay.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.countDay.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
        <Row>
          <Col>
            <CommandPanelColor
              label="Розрахувати"
              disabled={readOnly}
              onClick={() => actions.onSR('COUNT')}
            />
          </Col>
        </Row>
      </Row>
    </Row>
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={50}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.familyStatus.label}
            value={data[md.columns.familyStatus.name]}
            onChange={async (e, value) => {
              await actions.onChange({
                [md.columns.familyStatus.name]: value,
              });
              actions.onSR('FAMILYSTATUS_ON_CHANGE');
            }}
            modelType="cat"
            modelName="familyStatus"
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.StringInput
            label="ІПН"
            value={data[md.columns.INN.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.INN.name]: value,
            })}
            maxLength={10}
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.SelectorInput
            label={md.columns.sex.label}
            value={data[md.columns.sex.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.sex.name]: value,
            })}
            readOnly={readOnly}
            values={sValues}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.educationI.label}
            value={data[md.columns.educationI.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.educationI.name]: value,
            })}
            modelType="cat"
            modelName="education"
            readOnly={readOnly}
          />
        </Col>
        <Col>
          <EditorControls.ItemPicker
            label="Папка (група, підрозділ)"
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="individuals"
            readOnly={readOnly}
            choiceSettings={selectionMode.folders}
          />
        </Col>
      </Row>
      <Tabs defaultActiveKey="employmentMain">
        {/* <Tab title={md.tables.familySt.label} eventKey="familySt"> */}
        {/*    <FamilyStTP data={data} actions={actions} readOnly={readOnly} /> */}
        {/* </Tab> */}
        {/* <Tab title={md.tables.educationF.label} eventKey="educationF"> */}
        {/*    <EducationFTP data={data} actions={actions} readOnly={readOnly} /> */}
        {/* </Tab> */}
        {/* <Tab title={md.tables.employment.label} eventKey="employment"> */}
        {/*    <EmploymentTP data={data} actions={actions} readOnly={readOnly} /> */}
        {/* </Tab> */}
        {/* <Tab title={md.tables.languageI.label} eventKey="languageI"> */}
        {/*    <LanguageITP data={data} actions={actions} readOnly={readOnly} /> */}
        {/* </Tab> */}
        {/* <Tab title={md.tables.professionI.label} eventKey="professionI"> */}
        {/*    <ProfessionITP data={data} actions={actions} readOnly={readOnly} /> */}
        {/* </Tab> */}
        {/* <Tab title={md.tables.experienceI.label} eventKey="experienceI"> */}
        {/*    <ExperienceITP data={data} actions={actions} readOnly={readOnly} /> */}
        {/* </Tab> */}
        {/* <Tab title={md.tables.experienceMain.label} eventKey="experienceMain"> */}
        {/*    <ExperienceMainTP data={data} actions={actions} readOnly={readOnly} /> */}
        {/* </Tab> */}
        {/* <Tab title={md.tables.rasp.label} eventKey="rasp"> */}
        {/*    <RaspTP data={data} actions={actions} readOnly={readOnly} /> */}
        {/* </Tab> */}
        {/* <Tab title={md.tables.postI.label} eventKey="postI"> */}
        {/*    <PostITP data={data} actions={actions} readOnly={readOnly} /> */}
        {/* </Tab> */}
        {sessionOptions.is_admin && (
          <Tab title={md.tables.rasp.label} eventKey="rasp">
            <RaspTP data={data} actions={actions} readOnly={readOnly} />
          </Tab>
        )}
        {/* <Tab title={md.tables.employment.label} eventKey="employment"> */}
        {/*    <EmploymentTP data={data} actions={actions} readOnly={readOnly} /> */}
        {/* </Tab> */}
        <Tab title={md.tables.employmentMain.label} eventKey="employmentMain">
          <Row>
            <Col>
              <span>{data[md.columns.inscriptionMain.name]}</span>
            </Col>
          </Row>
          <EmploymentMainTP data={data} actions={actions} readOnly={readOnly} />
          {CountLabel}
        </Tab>
        <Tab title={md.tables.employment308.label} eventKey="employment308">
          <Row>
            <Col>
              <span>{data[md.columns.inscription308.name]}</span>
            </Col>
          </Row>
          <Employment308TP data={data} actions={actions} readOnly={readOnly} />
          {CountLabel}
        </Tab>
        <Tab title={md.tables.employmentAll.label} eventKey="employmentAll">
          <Row>
            <Col>
              <span>{data[md.columns.inscriptionAll.name]}</span>
            </Col>
          </Row>
          <EmploymentAllTP data={data} actions={actions} readOnly={readOnly} />
          {CountLabel}
        </Tab>
      </Tabs>
    </Container>
  );
};

Individuals.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default Individuals;
