import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { font } from '../../../../constants/globalStyle';

export const DivStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: ${(props) => props.alignStart && 'start'};
  position: relative;
  border-radius: 4px;
  background: ${({ noBackground }) => (noBackground ? '' : 'white')};
  border: ${({ noBorder }) => (noBorder ? '0' : '1px solid #afbbcc')};
`;

// export const InputStyled = styled.input`
export const TextareaStyled = styled.textarea.attrs((props) => ({
  rows: props.rows,
}))`
  resize: none;
  padding: 2px;
  border: 1px solid transparent;
  width: 100%;
  min-width: 1px;
  outline: none;
  border-bottom-color: ${(props) => props.required && 'red'};
  background-color: ${(props) => (props.border ? 'white' : 'transparent')};
  position: relative;
  border: ${(props) => props.border && '1px solid #afbbcc'};
  border-radius: ${(props) => props.border && '4px'};
  ${font};
  ${(props) => (props.isFocusCell ? 'autoFocus' : '')};
`;

TextareaStyled.propTypes = {
  rows: PropTypes.number,
};
TextareaStyled.defaultProps = {
  rows: 1,
};

export const InputStyled = ({
  value, isFocusCell, rows,
}) => {
  const [textCell, setValue] = useState(value);

  const refInput = useRef(null);

  useEffect(() => {
    if (isFocusCell) refInput.current.focus();
  });

  return (
    <TextareaStyled
      value={textCell}
      ref={refInput}
      rows={rows}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
};

InputStyled.propTypes = {
  value: PropTypes.string,
  isFocusCell: PropTypes.bool,
  rows: PropTypes.number,
};
InputStyled.defaultProps = {
  rows: 0,
  value: '',
  isFocusCell: false,
};

export const ButtonStyled = styled.button.attrs(() => ({
  tabIndex: -1,
}))`
  padding: 2px 6px;
  filter: ${(props) => props.disabled && 'grayscale(1) blur(1px)'};
  border-radius: 4px;
  color: black;
  border: 1px solid #afbbcc;
  background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  // background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(231,238,246,0.88)),#f3f3f3
  cursor: pointer;
  outline: none;
  &:hover{
    background: #AAC6E6;
    }
   :focus {_outline:none; outline: 1px black solid;}
   
`;
export const ButtonModalStyled = styled(ButtonStyled)`
`;

export const ButtonCleanStyled = styled(ButtonStyled)`
  padding: 2px 8px;
  //border-left: none;
`;

export const ButtonOpenStyled = styled(ButtonStyled)`
  //padding: 2px 8px;
  //border-left: none;
  //border-right: none;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  height: 1.8rem;
  &>*:not(:first-child):not(:last-child){
    border-radius: 0;
  }
  &>:first-child:not(:last-child){
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &>*+:last-child{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const DivDropStyled = styled.div`
  border-radius:.28571429rem;
  // border: 1px solid rgba(34,36,38,.15);
  border: 1px solid #afbbcc;
  padding: 5px;
  margin: 3px 0;
  position: absolute;
  z-index: 10;
  background: white;
  width: 100%;
  max-height: 15em;
  overflow: auto;
  top: ${({ top }) => `${top}px;`}
`;

export const DivInnerDropStyled = styled.div`
  padding: 4px;
  border: ${({ active }) => (active ? '1px solid #AAC6E6' : '1px solid #DDE2E9')};
  background: ${({ active }) => active && 'linear-gradient(0deg, #D9EEDE, #D9EEDE), #008F21'};
  &:hover{
    background: linear-gradient(0deg,rgba(255,255,255,0.88),rgba(255,255,255,0.88)),#4281C9;
  }
`;

const fadeIn = keyframes`
  0%,
  100% {
    box-shadow: 4px 4px rgb(79,77,73), -4px 4px rgb(223,223,223), -4px -4px rgb(79,77,73), 4px -4px rgb(223,223,223);  
  }
  25% {
    box-shadow: -4px 4px rgb(223,223,223), -4px -4px rgb(79,77,73), 4px -4px rgb(223,223,223), 4px 4px rgb(79,77,73);
  }
  50% {
    box-shadow: -4px -4px rgb(79,77,73), 4px -4px rgb(223,223,223), 4px 4px rgb(79,77,73), -4px 4px rgb(223,223,223);
  }
  75% {
    box-shadow: 4px -4px #dfdfdf, 4px 4px #4f4d49, -3px 3px #dfdfdf, -4px -4px #4f4d49;
  }
`;

const bigFadeIn = keyframes`
  0%,
  100% {
    box-shadow: 10px 10px #486498, -10px 10px #cbe2f2, -10px -10px #486498, 10px -10px #cbe2f2;  
  }
  25% {
    box-shadow: -10px 10px #cbe2f2, -10px -10px #486498, 10px -10px #cbe2f2, 10px 10px #486498;
  }
  50% {
    box-shadow: -10px -10px #486498, 10px -10px #cbe2f2, 10px 10px #486498, -10px 10px #cbe2f2;
  }
  75% {
    box-shadow: 10px -10px #cbe2f2, 10px 10px #486498, -10px 10px #cbe2f2, -10px -10px #486498;
  }
`;

export const BigDivLoadStyled = styled.div`
  margin: 30px;          
  width: 11px;  
  height: 11px;
  border-radius: 100%;
  box-shadow: 3px 3px #486498, -3px 3px #90c8f0, -3px -3px #486498, 3px -3px #dfdfdf;
  animation: ${bigFadeIn} ease infinite 4.6s;
`;

export const DivLoadStyled = styled.div`
  margin-top: 5px;          
  width: 5px;  
  height: 5px;
  border-radius: 100%;
  box-shadow: 3px 3px rgb(79,77,73), -3px 3px rgb(223,223,223), -3px -3px rgb(79,77,73), 3px -3px rgb(223,223,223);
  animation: ${fadeIn} ease infinite 4.6s;
`;

export const LoadStyled = styled.div`
  padding: 8px 10px;
  // border-top: 1px solid rgba(34,36,38,.15);
  border: none;
  // border-bottom: 1px solid rgba(34,36,38,.15);
  border-left: none;
  background-color: transparent;
  }
`;
