import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const StyledLoaderContainer = styled.div`
  position: fixed;
  z-index: 1031;
  left: calc(50% - .5em);
  top: calc(50% - .5em);
`;

const Loader = ({ text }) => (
  <StyledLoaderContainer className="p-3 shadow-lg rounded bg-white d-flex align-items-center">
    <Spinner animation="border" className="me-2" />
    <span className="p-1">{text}</span>
  </StyledLoaderContainer>
);

Loader.propTypes = {
  text: PropTypes.string,
};

Loader.defaultProps = {
  text: 'Зачекайте, будь ласка',
};

export default Loader;
