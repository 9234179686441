import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { baseDoc as md } from '../../../../constants/meta/catalogs/baseDoc';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';

const BaseDoc = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;
  const mainPane = (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.fullName.label}
            value={data[md.columns.fullName.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.fullName.name]: value,
            })}
            maxLength={18}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <EditorControls.DateInput
            label={md.columns.dateBase.label}
            value={data[md.columns.dateBase.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.dateBase.name]: value,
            })}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row>
        <EditorControls.CheckboxInput
          controlId={`inActive-${data.id}`}
          label={md.columns.inActive.label}
          value={data[md.columns.inActive.name]}
          onChange={(e, value) => actions.onChange({
            [md.columns.inActive.name]: value,
          })}
          readOnly={readOnly}
        />
      </Row>
      <Row>
        <EditorControls.CheckboxInput
          controlId={`isFact-${data.id}`}
          label={md.columns.isFact.label}
          value={data[md.columns.isFact.name]}
          onChange={(e, value) => actions.onChange({
            [md.columns.isFact.name]: value,
          })}
          readOnly={readOnly}
        />
      </Row>
    </Container>
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <EditorControls.StringInput
            label={md.columns.name.label}
            value={data[md.columns.name.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.name.name]: value,
            })}
            maxLength={100}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      {mainPane}
      <Row>
        <Col>
          <EditorControls.ItemPicker
            label={md.columns.parent.label}
            value={data[md.columns.parent.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.parent.name]: value,
            })}
            modelType="cat"
            modelName="baseDoc"
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </Container>
  );
};

BaseDoc.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    repr: PropTypes.string,
    [md.columns.code.name]: PropTypes.string,
    [md.columns.name.name]: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool.isRequired,
  }).isRequired,
};

export default BaseDoc;
