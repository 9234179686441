import styled from 'styled-components';

const MonthGrid = styled.div`
  display: grid;
  grid-template-areas: 
          "1fr 1fr 1fr"
          "1fr 1fr 1fr"
          "1fr 1fr 1fr"
          "1fr 1fr 1fr";
  & > .btn {
    border-radius: 0;
    position: relative;
  }
  & > .btn:first-child {
    border-radius: 0.25rem 0 0 0 ;
  }
  & > .btn:nth-child(3) {
    border-radius: 0 0.25rem 0 0 ;
  }
  & > .btn:nth-child(10) {
    border-radius: 0 0 0 0.25rem ;
  }
  & > .btn:last-child {
    border-radius: 0 0 0.25rem 0 ;
  }
`;

export default MonthGrid;
