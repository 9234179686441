import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Tabs, Tab, Col,
} from 'react-bootstrap';
import md from '../../../../constants/meta/documents/changeToStaffList';
import { GeneralTP, MainTP } from './tabs';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { CommandPanelColor } from '../../../../components/Form/styledForm';

const ChangeToStaffListTabs = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;
  const FI = data[md.columns.csu.name];
  const FiOwnerFilter = useMemo(
    () => [{ fieldName: 'Владелец', value: FI }],
    [FI],
  );
  return (
    <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
      <Tab title={md.tables.general.label} eventKey="general">
        <CommandPanelColor
          label="Розрахувати"
          disabled={readOnly}
          onClick={() => actions.onSR('RECOUNT')}
        />
        <GeneralTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.main.label} eventKey="main">
        <CommandPanelColor
          label="Розрахувати"
          disabled={readOnly}
          onClick={() => actions.onSR('RECOUNT_MAIN')}
        />
        <MainTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Col>
        <EditorControls.ItemPicker
          label={md.columns.signatureVariants.label}
          value={data[md.columns.signatureVariants.name]}
          onChange={(e, value) => actions.onChange({
            [md.columns.signatureVariants.name]: value,
          })}
          modelType="cat"
          modelName="signatureVariants"
          filter={FiOwnerFilter}
          readOnly={readOnly}
        />
      </Col>
      <Col>
        <EditorControls.ItemPicker
          label={md.columns.author.label}
          value={data[md.columns.author.name]}
          onChange={(e, value) => actions.onChange({
            [md.columns.author.name]: value,
          })}
          modelType="cat"
          modelName="users"
          readOnly
        />
      </Col>
    </Tabs>
  );
};

ChangeToStaffListTabs.propTypes = {
  data: PropTypes.shape({

  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default ChangeToStaffListTabs;
