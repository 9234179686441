import React from 'react';
import PropTypes from 'prop-types';
import {
  Tabs, Tab, Col,
} from 'react-bootstrap';
import md from '../../../../constants/meta/documents/calcTarifList';
import { GeneralTP, BaseTP } from './tabs';
import { EditorControls } from '../../../../components/bootstrap_components/editorControls';
import { CommandPanelColor } from '../../../../components/Form/styledForm';

const CalcTarifListTabs = ({ data, actions, permissions }) => {
  const readOnly = !permissions.canChange;

  return (
    <Tabs defaultActiveKey="general" className="m-0 mt-2 border-bottom">
      <Tab title={md.tables.general.label} eventKey="general">
        <CommandPanelColor
          label="Заповнити"
          disabled={readOnly}
          onClick={() => actions.onSR('FILL')}
        />
        <GeneralTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.tables.base.label} eventKey="base">
        <BaseTP data={data} actions={actions} readOnly={readOnly} />
      </Tab>
      <Tab title={md.columns.note.label} eventKey="main">
        <Col>
          <EditorControls.TextInput
            label={md.columns.note.label}
            value={data[md.columns.note.name]}
            onChange={(e, value) => actions.onChange({
              [md.columns.note.name]: value,
            })}
            readOnly={readOnly}
            rows={5}
          />
        </Col>
      </Tab>
    </Tabs>
  );
};

CalcTarifListTabs.propTypes = {
  data: PropTypes.shape({
  }).isRequired,
  actions: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    onChange: PropTypes.func.isRequired,
    onSR: PropTypes.func.isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    canChange: PropTypes.bool,
  }).isRequired,
};

export default CalcTarifListTabs;
