/**
 * @const
 * @property {string} equal
 * @property {string} greater
 * @property {string} greaterOrEqual
 * @property {string} less
 * @property {string} lessOrEqual
 * @property {string} notEqual
 * @property {string} inHierarchy
 * @property {string} inList
 * @property {string} inListByHierarchy
 * @property {string} filled
 * @property {string} beginsWith
 * @property {string} notInHierarchy
 * @property {string} notInList
 * @property {string} notInListByHierarchy
 * @property {string} notFilled
 * @property {string} notBeginsWith
 * @property {string} notLike
 * @property {string} notContains
 * @property {string} like
 * @property {string} contains
 */
export const comparisonTypes = {
  equal: { value: 'EQUAL', label: 'Дорівнює' },
  greater: { value: 'GREATER', label: 'Більше' },
  greaterOrEqual: { value: 'GREATEROREQUAL', label: 'Більше або дорівнює' },
  less: { value: 'LESS', label: 'Меньше' },
  lessOrEqual: { value: 'LESSOREQUAL', label: 'Меньше або дорівнює' },
  notEqual: { value: 'NOTEQUAL', label: 'Не дорівнює' },
  inHierarchy: { value: 'INHIERARCHY', label: 'В групі' },
  inList: { value: 'INLIST', label: 'В списку' },
  inListByHierarchy: { value: 'INLISTBYHIERARCHY', label: 'В групі зі списку' },
  filled: { value: 'FILLED', label: 'Заповнено' },
  beginsWith: { value: 'BEGINSWITH', label: 'Починається з' },
  notInHierarchy: { value: 'NOTINHIERARCHY', label: 'Не в групі' },
  notInList: { value: 'NOTINLIST', label: 'Не в списку' },
  notInListByHierarchy: { value: 'NOTINLISTBYHIERARCHY', label: 'В групі зі списку' },
  notFilled: { value: 'NOTFILLED', label: 'Не заповнено' },
  notBeginsWith: { value: 'NOTBEGINSWITH', label: 'Не починається з' },
  notLike: { value: 'NOTLIKE', label: 'Не подобно' },
  notContains: { value: 'NOTCONTAINS', label: 'Не містить' },
  like: { value: 'LIKE', label: 'Подобно' },
  contains: { value: 'CONTAINS', label: 'Містить' },
};
export default comparisonTypes;
